import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../common-modules/services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-my-team-goals',
  templateUrl: './my-team-goals.component.html',
  styleUrls: []
})
export class MyTeamGoalsComponent implements OnInit {
MyTeamGoalsData = [];
cols = [];
userData:any = [];
goalsTotal:any;
completed:any;
pending:any;
blocked:any;
completedPerc:any;
pendingPerc:any;
blockedPerc:any;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService) { }
  ngOnInit(): void {
      
      
      if (localStorage.getItem("userData") === null) {
         this.router.navigate(['login']);
      }else{
         this.userData = JSON.parse(localStorage.getItem("userData"));
      }
      this.cols = [
        { field: 'title', header: 'Goal Title' },
        { field: 'priority', header: 'Priority' },
        { field: 'percCompleted', header: 'Percentage Completed'}
     ];
    this.goalsTotal = 0;
    this.pending = 0;
    this.completed = 0;
    this.blocked = 0;
    this.pendingPerc = 0;
    this.completedPerc = 0;
    this.blockedPerc = 0;
     this.getAllGoals_ofMyTeam_Data();
   
  }
  
  getAllGoals_ofMyTeam_Data(){
      var form = new FormData();
      form.append("companyId",this.userData.companyId);
      form.append("managerId",this.userData.id);
     
  		let response = this.apiService.getAllGoals_ofMyTeam(form).subscribe(
        response  => {
        if(response.goalDetails){
        	this.MyTeamGoalsData = response.goalDetails;
            this.completed = response.completed;
        	this.pending = response.pending;
        	this.blocked = response.blocked;
        	this.goalsTotal =  this.MyTeamGoalsData.length;
        	this.completedPerc = Math.round(this.completed*100/this.goalsTotal);
        	this.pendingPerc = Math.round(this.pending*100/this.goalsTotal);
        	this.blockedPerc = Math.round(this.blocked*100/this.goalsTotal);
        // 	document.getElementsByClassName("progress-bar progress-bar-danger p-blocked")[0].value = this.blocked+" Goals Blocked.";
        // 	document.getElementsByClassName("progress-bar progress-bar-warning p-inprogress")[0].value = this.pending+" Goal Inprogress";
        // 	document.getElementsByClassName("progress-bar progress-bar-success p-accomplished")[0].value = this.completedPerc+" Goal Accomplished";
        // 	console.log(this.completedPerc);
        // 	console.log(this.pendingPerc);
        // 	console.log(this.blockedPerc);
        }
        },
          error => (
            console.log(error)
          )
    )
  }
}
