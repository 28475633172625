import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../../common-modules/services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {ChartModule} from 'primeng/chart';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userData:any = [];
  myReviews:any = [];
  MyGoalsData:any = [];
  roleId:any;
  notifications:any = [];
  notificationsLength:any = 0;
  constructor(private route:Router,private apiService: ApiService) { }
  companyLogo = '';

  ngOnInit(): void {
      
       if (localStorage.getItem("userData") === null) {
             this.route.navigate(['login']);
        }else{
             this.userData = JSON.parse(localStorage.getItem("userData"));
             this.roleId= this.userData.roleId;
            //  console.log("companylogo" + this.userData.companylogo);
             this.companyLogo = this.userData.companylogo;
             this.getUserNotification_Data();
        }
  }
  
    getUserNotification_Data(){
      var form = new FormData();
      form.append("userId",this.userData.id);
     
  		let response = this.apiService.getUserNotification(form).subscribe(
        response  => {
        if(response.status == 1){
        	this.notifications = response.notifications ;
        	this.notificationsLength = this.notifications.length;
        // 	console.log('notifications',this.notifications);
        }else{
            this.notifications = [] ;
        	this.notificationsLength = 0;
        }
        },
          error => (
            console.log(error)
          )
    )
  }
  
  clearAll(){
      var form = new FormData();
      form.append("userId",this.userData.id);
  		let response = this.apiService.markReadUserNotification(form).subscribe(
        response  => {
        if(response.status == 1){
            this.getUserNotification_Data();
        }
        },
          error => (
            console.log(error)
          )
    )
  }
  
  clear(id){
      var form = new FormData();
      form.append("id",id);
     
  		let response = this.apiService.markReadUserNotification(form).subscribe(
        response  => {
        if(response.status == 1){
            this.getUserNotification_Data();
        }
        },
          error => (
            console.log(error)
          )
    )
  }

  logout(){
    localStorage.removeItem('userData');
    localStorage.clear();
    this.route.navigate(['login']);
  }
}
