import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup,FormsModule, ReactiveFormsModule, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../common-modules/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser'
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgModule } from '@angular/core';
import {MessageService} from 'primeng/api';
import { StripeScriptTag } from "stripe-angular";
declare var jQuery: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
    
    form: FormGroup; 
    submitted = false;
    private formSubmitAttempt: boolean;
    years = [];
    public screenImgSrcPath: string = 'assets/images/login-screen.png';
    public logoSrcPath: string = 'assets/logo/logo.png';
    cardCaptureReady = false
    
    public foccused: number;
   
    public isloginForm: boolean = true;
    public isforgotpassword: boolean = false;
    public isclientsignupForm: boolean = false;
    step: any =1;
    signupLetstartSubmitted = false;
    signupAboutYourSelfSubmitted = false;
    customizeTeamSubmitted = false;
    letsStartForm: FormGroup;
    aboutYourSelfForm: FormGroup;
    customizeTeamForm: FormGroup;
    paymentForm: FormGroup;
    companyName= '';
    businessEmailId= '';
    signupPassword= '';
    firstName= '';
    lastName= '';
    jobTitle= '';
    phone= '';
    leadClientID: any;
    allPlans:any = [];
    paymentHandler:any = null;
    amountBasedOnSelection='';
    noOfEmployees:any;
    planId:any;
    planName:any;
    userId:any;
    companyId:any;
    userData:any;
    stripeResponse:any;
    publishKey:any;
    amountBasedOnSelectionView:any;
    termsAndConditions:any;
    policy:any;
    paymentSubmitted = false;
    
    constructor(
     private fb: FormBuilder,
     private apiService: ApiService,
     private route:Router,
     private messageService: MessageService,
     private stripeScriptTag: StripeScriptTag,
     private ngZone: NgZone
    ) {}
    ngOnInit() 
    {
        // this.invokeStripe();
        this.form = this.fb.group({ 
          email: ['', Validators.required],
          password: ['', Validators.required]
        });
        
        this.letsStartForm = this.fb.group({
            companyName: ['', Validators.required],
            businessEmailId: ['', [Validators.required, Validators.email]],
            signupPassword: ['', [Validators.required, Validators.minLength(6)]],
            iAccept: ['', Validators.required] 
        });
        
        this.aboutYourSelfForm =  this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            jobTitle: ['', Validators.required],
            phone: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
        });
        
        this.paymentForm =  this.fb.group({
            cardnumber: ['', [Validators.required,Validators.pattern(/^-?\d+\.?\d*$/), Validators.minLength(16),Validators.maxLength(16)]],
            mm: ['', Validators.required],
            cvv: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(3)]],
            yy: ['', Validators.required],
            amountBasedOnSelectionView: ['']
        });
        
        var year = new Date().getFullYear();
        this.years.push(year);
        for (var i = 1; i < 9; i++) {
            this.years.push(year + i);
        }
        // if (!this.stripeScriptTag.StripeInstance) {
        //   this.stripeScriptTag.setPublishableKey('pk_test_ipp96XZikw6j7s9J7VTThAot');
        // }
        this.getPaymentSettings();
        this.getTermsAndCondition();
        this.getPrivacy();
        this.noOfEmployees = new FormControl('', [
          Validators.required
        ]);
        
    }
    get fls() { return this.letsStartForm.controls; }
    
    get fay() { return this.aboutYourSelfForm.controls; }
    get fap() { return this.paymentForm.controls; }
    onSubmitLetsStart() {
        this.signupLetstartSubmitted = true;
        // stop here if form is invalid
        if (this.letsStartForm.invalid) {
            return;
        }
        this.step = this.step + 1;
    }
    
    onSubmitAboutYourSelf() {
        this.signupAboutYourSelfSubmitted = true;
        // stop here if form is invalid
        if (this.aboutYourSelfForm.invalid) {
            return;
        }
        var form = new FormData();
        form.append("email" , this.letsStartForm.value.businessEmailId);
        form.append("password" , this.letsStartForm.value.signupPassword);
        form.append("companyName" , this.letsStartForm.value.companyName);
        form.append("firstName" , this.aboutYourSelfForm.value.firstName);
        form.append("lastName" , this.aboutYourSelfForm.value.lastName);
        this.businessEmailId = this.letsStartForm.value.businessEmailId;
        this.companyName = this.letsStartForm.value.companyName;
        this.signupPassword = this.letsStartForm.value.signupPassword;
        this.firstName = this.aboutYourSelfForm.value.firstName;
        this.lastName = this.aboutYourSelfForm.value.lastName;
        form.append("jobTitleId" , this.aboutYourSelfForm.value.jobTitleId);
        form.append("phone" , this.aboutYourSelfForm.value.phone);
        let response = this.apiService.signupStep1(form).subscribe(
            response  => {
              if(response.leadClientID !== 0){
                  this.leadClientID = response.leadClientID;
                //   this.getAllMasterPlansData();
                  this.step = this.step + 1;
              }else{
                this.messageService.add({severity:'error', summary: 'error', detail:response.message});
               	return false;
              }
            },
            error => {
                console.log(error);
                this.messageService.add({severity:'error', summary: 'error', detail:error});
                return false;
            }
        );
    }
    
    public isFieldInvalid(field: string) :boolean
    {
        return (
          (!this.form.get(field).valid && this.form.get(field).touched) ||
          (this.form.get(field).untouched && this.formSubmitAttempt)
        );
    }
    
    public  addBorder(index: number) 
    {
        this.foccused = index;
     
    }
 
    public removeBorder():void
    {
        this.foccused = undefined;
    }
    public isFocussed(index: number):boolean 
    {
        return index === this.foccused;
       
    }
    
    public onSubmit():void
    {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        let response = this.apiService.login(this.form.value).subscribe(
          response  => {
            this.submitted = false;  
            if(response.status == 1){
              localStorage.setItem("userData",JSON.stringify(response.userData));
              localStorage.setItem("token", JSON.stringify(response.token))
            //   if(response.userData.roleId == 1){
            //     this.route.navigate(['superadmin/dashboard']);
            //   }else 
              if(response.userData.roleId == 2){
                this.messageService.add({severity:'success', summary: 'Success' , detail:'Logged In successfully'});  
                this.route.navigate(['hradmin/dashboard']);
              }else if(response.userData.roleId == 3 || response.userData.roleId == 4){
                this.messageService.add({severity:'success', summary: 'Success' , detail:'Logged In successfully'});  
                this.route.navigate(['employee/dashboard']);
              }else{  
                 this.messageService.add({severity:'error', summary: 'error', detail:'Invalid User details'});
              }
            }else{
                this.messageService.add({severity:'error', summary: 'error', detail:response.message});
            }
          },
          error => (
            // console.log(error)
            this.messageService.add({severity:'error', summary: 'error',  detail:'Something Went Wrong'})
          )
        );
    }
    
    public signupOpen():void 
    {
        this.isloginForm = false;
        this.isclientsignupForm = true;
        this.isforgotpassword = false;
        // this.makePayment(500);
    }
    
    public loginOpen():void 
    {
        this.isloginForm = true;
        this.isclientsignupForm = false;
        this.isforgotpassword = false;
    }
    
    public forgotOpen():void 
    {
        this.isloginForm = false;
        this.isclientsignupForm = false;
        this.isforgotpassword = true;
    }
    
    submit()
    {
        if(this.amountBasedOnSelection == '' || this.amountBasedOnSelection == 'undefined' || this.amountBasedOnSelection === null){
            this.messageService.add({severity:'error', summary: 'error', detail:'Please select plan. If there is no plan please contact administrator.'});
            return false;
        }else{
            this.step = this.step + 1;    
        }
    }
    
    previous()
    {
        this.step = this.step - 1;
    }
    loadStripe(key) {
        (<any>window).Stripe.setPublishableKey(key);
    }
    getCardToken(){
        if(!window['Stripe']) {
            alert('Oops! Stripe did not initialize properly.');
            return;
        }
           
        this.paymentSubmitted = true;
        // stop here if form is invalid
        // if (this.paymentForm.invalid) {
        //     console.log(this.paymentForm.invalid);
        //     return;
        // }
        (<any>window).Stripe.card.createToken({
            number: this.paymentForm.value.cardnumber,
            exp_month: this.paymentForm.value.mm,
            cvc: this.paymentForm.value.cvv,
            exp_year: this.paymentForm.value.yy
        },(status:number,response:any)=>{
          console.log(response);  
          if(response.error){
              this.paymentSubmitted = false;
              this.messageService.add({severity:'error', summary: 'error', detail:response.error.message});
          }else{
            this.setStripeToken(response);
          }
        })
    }
    
//   setStripeToken( token: stripe.Token ){
  setStripeToken(token){
      var form = new FormData();
        // form.append("email" , this.letsStartForm.value.businessEmailId);
        form.append("tokenId" , token.id);
        form.append("amount" , this.amountBasedOnSelection);
        form.append("email" , this.businessEmailId);
        form.append("planName" , this.planName);
        var userNameForP = this.firstName+" "+this.lastName;
        form.append("name" , userNameForP);
        var descr = "Payment to Aestimo for company "+this.companyName;
        form.append("description" , descr);
        let response = this.apiService.payment(form).subscribe(
            response  => {
                if(response.status  == "success"){
                    alert("Thank you for your payment.  You will be redirected to company profile page in few seconds.");
                    this.messageService.add({severity:'success', summary: 'success', detail:'Payment Done Successfully.'});
                    this.stripeResponse = response;
                    this.signupCompany();
                    // this.assignFirstPlanToCompany();
                    // alert('Payment Done Successfully.');
                }else{
                    this.paymentSubmitted = false;
                    this.messageService.add({severity:'success', summary: 'success', detail:response.message});
                    // alert('Something went wrong.');
                }
            },
            error => {
                console.log(error);
                this.paymentSubmitted = false;
                this.messageService.add({severity:'error', summary: 'error', detail:'Something went wrong.'});
                return false;
            }
        );
    // console.log('Stripe Token', token)
    // console.log('this.stripeScriptTag', this.stripeScriptTag)
    
  }
  
  signupCompany(){
    var form = new FormData();
    form.append("email" , this.businessEmailId);
    form.append("leadClientID" , this.leadClientID);
    form.append("amount" , this.amountBasedOnSelection);
    form.append("companyName" , this.companyName);
    form.append("invoiceId" , this.stripeResponse.subscriptionData.latest_invoice);
    form.append("planName" , this.planName);
    var userNameForP = this.firstName+" "+this.lastName;
    form.append("name" , userNameForP);
    let response = this.apiService.signup(form).subscribe(
        response  => {
            if(response.status  == 1){
                this.companyId = response.companyId;
                this.userId = response.userID;
                this.messageService.add({severity:'success', summary: 'success', detail:response.message});
                this.assignFirstPlanToCompany();
            }else{
                  this.paymentSubmitted = false;
                this.messageService.add({severity:'error', summary: 'error', detail:response.message});
            }
        },
        error => {
            console.log(error);
            return false;
        }
    );
  }
  
  assignFirstPlanToCompany(){
      var form = new FormData();
        // form.append("maxEmployee" , this.noOfEmployees);
        form.append("clientId" , this.companyId);
        form.append("planId" , this.planId);
        form.append("amount_paid" , this.amountBasedOnSelection);
        form.append("paymentType" , '1');
        form.append("stripeResponse" , JSON.stringify(this.stripeResponse));
        let response = this.apiService.assignFirstPlanToCompany(form).subscribe(
            response  => {
                if(response.status  == 1){
                    this.messageService.add({severity:'success', summary: 'success', detail:response.message});
                    this.logInAsClient(this.businessEmailId);
                }else{
                      this.paymentSubmitted = false;
                    this.messageService.add({severity:'error', summary: 'error', detail:response.message});
                }
            },
            error => {
                console.log(error);
                return false;
            }
        );
  }
  
  logInAsClient(email){
        var form = new FormData();
        form.append('email',email);
      	let response = this.apiService.getUserDetails(form).subscribe(
        response  => {
        if(response.status == 1){
        	this.userData = response.userData;
            localStorage.setItem("userData",JSON.stringify(this.userData[0]));
            this.ngZone.run(()=>{
                this.route.navigate(['hradmin/logo-upload']);
            });    
        	
        }else{
            this.messageService.add({severity:'error', summary: 'error', detail:response.message});
        }
        //   console.log(response);
        },
          error => (
            console.log(error)
          )
    )
  }
  
  getAllMasterPlansData(){
        var form = new FormData();
        form.append("maxEmployee" , this.noOfEmployees);
        let response = this.apiService.getAllMasterPlans(form).subscribe(
            response  => {
                console.log(response);
                if(response.message  == "Success."){
                    this.amountBasedOnSelection = response.plans[0].price_Month;
                    this.amountBasedOnSelectionView = response.plans[0].price_Month;
                    // this.paymentForm = this.fb.group({
                    //     amountBasedOnSelectionView: new FormControl(this.amountBasedOnSelectionView)
                    // });
                    this.paymentForm =  this.fb.group({
                        cardnumber: ['', Validators.required],
                        mm: ['', Validators.required],
                        cvv: ['', Validators.required],
                        yy: ['', Validators.required],
                        amountBasedOnSelectionView: new FormControl(this.amountBasedOnSelectionView)
                    });
                    this.planId = response.plans[0].id;
                    this.planName = response.plans[0].title;
                }else{
                    this.messageService.add({severity:'error', summary: 'error', detail:'There is no plan right now related to number of employee you have selected. Please contact administrator.'});
                }
            },
            error => {
                console.log(error);
                return false;
            }
        );
  }
  
    getPaymentSettings(){
        var form = new FormData();
        let response = this.apiService.getPaymentSettings(form).subscribe(
            response  => {
                // console.log(response);
                if(response.status  == 1){
                    this.publishKey = response.data[0].publishkey;
                    this.loadStripe(this.publishKey);
                    // this.stripeScriptTag.setPublishableKey(this.publishKey);
                   
                }else{
                    console.log('stripe keys not found.');
                    // this.messageService.add({severity:'error', summary: 'error', detail:'There is no plan right now related to number of employee you have selected. Please contact administrator.'});
                }
            },
            error => {
                console.log(error);
                return false;
            }
        );
  }
  
  getTermsAndCondition(){
        var form = new FormData();
        form.append('userId','0');
        let response = this.apiService.getAllTerms(form).subscribe(
            response  => {
                // console.log(response);
                if(response.status  == 1){
                    this.termsAndConditions = response.Terms[0].termsContent;
                    // this.stripeScriptTag.setPublishableKey(this.publishKey);
                   
                }else{
                    console.log('stripe keys not found.');
                    // this.messageService.add({severity:'error', summary: 'error', detail:'There is no plan right now related to number of employee you have selected. Please contact administrator.'});
                }
            },
            error => {
                console.log(error);
                return false;
            }
        );
  }
  
  getPrivacy(){
        var form = new FormData();
        form.append('userId','0');
        form.append('privacy','1');
        let response = this.apiService.getAllTerms(form).subscribe(
            response  => {
                // console.log(response);
                if(response.status  == 1){
                    this.policy = response.Terms[0].privacyContent;
                    // this.stripeScriptTag.setPublishableKey(this.publishKey);
                   
                }else{
                    console.log('stripe keys not found.');
                    // this.messageService.add({severity:'error', summary: 'error', detail:'There is no plan right now related to number of employee you have selected. Please contact administrator.'});
                }
            },
            error => {
                console.log(error);
                return false;
            }
        );
  }
  
//   setStripeCustomers( customers: stripe.customers ){
//     console.log('Stripe customers', customers)
//   }
  setStripeSource( source: stripe.Source ){
    console.log('Stripe Source', source)
  }
  
  startPayment(token){
      console.log('Stripe Token', token)
  }
  
  getttt(token){
      console.log('Stripe Token', token)
  }
}