import { Component, OnInit } from '@angular/core';
import { FormGroup,FormsModule, ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../common-modules/services/api.service';
import { Router } from '@angular/router';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-superadminlogin',
  templateUrl: './superadminlogin.component.html',
  styleUrls: ['./superadminlogin.component.css'],
  providers: [MessageService]

})

export class SuperadminloginComponent implements OnInit {
    
    public userLoginDetails : userLoginDetails;
    public foccused: number;
    form: FormGroup; 
    submitted = false;
    
    
    constructor(
        private fb: FormBuilder,
        private apiService: ApiService,
        private route:Router,
        private messageService: MessageService
    ) {}

    ngOnInit() 
    {
        this.form = this.fb.group({ 
          email: ['', Validators.required],
          password: ['', Validators.required]
        });
        this.userLoginDetails = new userLoginDetails();
    }

   
    public  addBorder (index: number) 
    {
        this.foccused = index;
    }

    public removeBorder():void
    {
        this.foccused = undefined;
    }

    public isFocussed(index: number):boolean 
    {
        return index === this.foccused;
    }

    public doLogin():void
    {
        this.submitted = true;
        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        this.apiService.login(this.form.value).subscribe(
            response  => {
                this.submitted = false;  
                if( response.status == 1 )
                {
                
                    if( response.userData.roleId == 1 )
                    {
                        this.messageService.add({severity:'success', summary: 'Success' , detail:'Logged In successfully'});
                        localStorage.setItem("userData",JSON.stringify(response.userData));
                        localStorage.setItem("token", JSON.stringify(response.token))
                        this.route.navigate(['superadmin/dashboard']);
                    }
                    else
                    {
                        this.messageService.add({severity:'error', summary: 'error', detail:'Invalid User details'});
                       
                    }
                }
                else
                {
                    this.messageService.add({severity:'error', summary: 'error',  detail:'Invalid User details'});
                }
            },
            error => (
                this.messageService.add({severity:'error', summary: 'error',  detail:'Something Went Wrong'})
            )
        );
    }
    
    
}

export class userLoginDetails 
{
    public email: string;
    public password: string;
}