import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../common-modules/services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser'


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: []
})
export class ProfileComponent implements OnInit {
  Profile = [];
  userData:any = [];
  constructor(private route : ActivatedRoute,
      private router : Router,
      private apiService : ApiService) {
  }
  ngOnInit(): void {
      if (localStorage.getItem("userData") === null) {
         this.router.navigate(['login']);
      }else{
         this.userData = JSON.parse(localStorage.getItem("userData"));
      }
      this.getUserDetails();
  }
  
  getUserDetails(){
      var form = new FormData();
      form.append("email", this.userData.email);
      
      	let response = this.apiService.getUserDetails(form).subscribe(
        response  => {
        if(response.userData){
        	this.Profile = response.userData;

        // 	console.log('Profile',this.Profile);
        	
        }
        //   console.log(response);
        },
          error => (
            console.log(error)
          )
    )
  }
}