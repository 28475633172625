 <div class="">
    <div class="container">
      <div class="page-heading">
        <h2>Change Password</h2>
        <ol class="breadcrumb p-0 m-0 bg-white">
          <li class="breadcrumb-item">
            <a class="text-secondary" routerLink="dashboard">Home</a>
          </li>

          

          <li class="breadcrumb-item active">Change Password</li>
        </ol>
      </div>
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-md-6 text-center">
          <img class="img-fluid" src="./assets/images/resetpassword.png">
        </div>
        <div class="col-md-6">
          <div class="login-content justify-content-center">
            <!--<form id="changepassword">-->
            <form [formGroup]="changepasswordForm" (ngSubmit)="onSubmit()">
              <div class="input-div one">
                <div class="i">
                  <i class="fas fa-lock" aria-hidden="true"></i>
                </div>
                <div class="div form-group">
                  <h5>Enter Previous Password</h5>
                  <input type="password" formControlName="oldPass" autocomplete="off" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.oldPass.errors }" />
                  <div *ngIf="submitted && f.oldPass.errors" class="invalid-feedback">
                        <div *ngIf="f.oldPass.errors.required">Old Password is required</div>
                    </div>
                </div>
              </div>

              <div class="input-div one">
                <div class="i">
                  <i class="fas fa-lock" aria-hidden="true"></i>
                </div>
                <div class="div">
                  <h5>Enter New Password</h5>
                  <input type="password" formControlName="newPass" autocomplete="off" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.newPass.errors }" />
                   <div *ngIf="submitted && f.newPass.errors" class="invalid-feedback">
                        <div *ngIf="f.newPass.errors.required">New Password is required</div>
                        <div *ngIf="f.newPass.errors.minlength">New Password must be at least 6 characters</div>
                    </div>
                </div>
              </div>

              <div class="input-div one">
                <div class="i">
                  <i class="fas fa-lock" aria-hidden="true"></i>
                </div>
                <div class="div">
                  <h5>Confirm New Password</h5>
                  <input type="password" formControlName="confirmPass" autocomplete="off" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPass.errors }" />
                  <div *ngIf="submitted && f.confirmPass.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmPass.errors.required">Confirm Password is required</div>
                        <div *ngIf="f.confirmPass.errors.mustMatch">Passwords must match</div>
                    </div>
                </div>
              </div>
              <button type="submit" class="login-btn"  >Save</button>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>