import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../common-modules/services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.css']
})
export class GoalsComponent implements OnInit {
MyGoalsData = [];
userData:any = [];
percentageCompleted:any = 0;
val: number;
currentDate: any;


cols = [];
  constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService) { }

  ngOnInit(): void {
    if (localStorage.getItem("userData") === null) {
      this.router.navigate(['login']);
    }else{
      this.userData = JSON.parse(localStorage.getItem("userData"));
    //   this.userId = this.userData.id;
    }
         this.getAllGoals_ofEmployee_Data();
      
      this.cols = [
        { field: 'title', header: 'Goal Title' },
        { field: 'priority', header: 'Priority' },
        { field: 'percCompleted', header: 'Percentage Completed'}
        ];
      this.currentDate = new Date();    
  }
  
  getAllGoals_ofEmployee_Data(){
      var form = new FormData();
      form.append("employeeId",this.userData.id);
     
  		let response = this.apiService.getAllGoals_ofEmployee(form).subscribe(
        response  => {
        if(response.goalDetails){
        	this.MyGoalsData = response.goalDetails;
        	
        }
        },
          error => (
            console.log(error)
          )
    )
  }
  
  valueChanged(e,i) {
        this.MyGoalsData[i].percCompleted = e;
        // console.log('e', e);
        // console.log('i',i);
        // console.log('this.MyGoalsData[i]',this.MyGoalsData[i]);
        var form = new FormData();
        form.append("percCompleted",e);
        form.append("employeeGoalId",this.MyGoalsData[i].employeeGoalId);
         
      		let response = this.apiService.updateEmployeeGoalPercentage(form).subscribe(
            response  => {
                alert('Percentage Change for selected goal.');
            // if(response.updatedEmployeeGoal > 0){
            // 	this.MyGoalsData = response.goalDetails;
            // 	console.log('MyGoalsData',this.MyGoalsData);
            	
            // 	console.log('checkMyGoalsData',this.MyGoalsData[0].title);
            // }
            //   console.log(response);
            },
              error => (
                console.log(error)
              )
        )
    }

}
