import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../common-modules/services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {ChartModule} from 'primeng/chart';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
@ViewChild('closeterms') closeterms;
userData:any = [];
roleId:any;
MyGoalsData = [];
Profile= [];
cols = [];
MyTeamAllReviews:any =[];
userPRData = [];
myTeamUserPRData = [];
PReviewAllMyData = [];
data: any;
options: any;
goalsCompleted: any = 0;
goalsInProgress: any = 0;
prsCompleted: any = 0;
prsInProgress: any = 0;
prs:any = [];
termsAndConditions:any;
trendChart:any = [];
termsAndConditionsId:any;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService) { 
                  
                  this.data = {
                        labels: [],
                        datasets: []
                    }
                    
            
    }
              
  selectData(event) {
        // this.messageService.add({severity: 'info', summary: 'Data Selected', 'detail': this.data.datasets[event.element._datasetIndex].data[event.element._index]});
    }
  ngOnInit(): void {
      if (localStorage.getItem("userData") === null) {
             this.router.navigate(['login']);
        }else{
             this.userData = JSON.parse(localStorage.getItem("userData"));
             this.roleId= this.userData.roleId;
            //  console.log("roleId" + this.userData.roleId);
        }
        this.getUserDetails_Data();
        this.checkUserHasacceptedTerms();
        this.getMyTeamAllReviews_Data();
     
        this.getAllGoals_ofEmployee_Data();
        this.getMyAllReviews();
      
      
      this.cols = [
        { field: 'title', header: 'Goal Title' },
        { field: 'priorityVal', header: 'Priority' },
        { field: 'percCompleted', header: 'Percentage Completed' },
    ];
  }
   checkUserHasacceptedTerms(){
        var form = new FormData();
        form.append('userId',this.userData.id);
        form.append('companyId',this.userData.companyId);
        form.append('termFor','1');
        let response = this.apiService.checkUserHasacceptedTerms(form).subscribe(
            response  => {
                // console.log(response);
                if(response.status == 0){
                    this.getTermsAndCondition();
                }else{
                    console.log('Terms and conditions not accepted.');
                }
            },
            error => {
                console.log(error);
                return false;
            }
        );
    }
  
    getTermsAndCondition(){
        var form = new FormData();
        form.append('userId','0');
        let response = this.apiService.getAllTerms(form).subscribe(
            response  => {
                // console.log(response);
                if(response.status  == 1){
                    this.termsAndConditions = response.Terms[0].termsContent;
                    this.termsAndConditionsId = response.Terms[0].id;
                    document.getElementById("openModalButton").click();
                }else{
                    console.log('Terms and conditions not found.');
                }
            },
            error => {
                console.log(error);
                return false;
            }
        );
    }
    
    acceptTerms(){
        var form = new FormData();
        form.append('acceptedBy',this.userData.id);
        form.append('companyId',this.userData.companyId);
        form.append('termId',this.termsAndConditionsId);
        let response = this.apiService.acceptUserTerms(form).subscribe(
            response  => {
                // console.log(response);
                if(response.status  == 1){
                   alert('You have successfully accepted terms.');
                    this.closeterms.nativeElement.click();
                }else{
                    console.log('Terms and conditions acception went wrong.');
                }
            },
            error => {
                console.log(error);
                return false;
            }
        );
    }
            
   getUserDetails_Data(){
      var form = new FormData();
      form.append("email", this.userData.email);
      
      	let response = this.apiService.getUserDetails(form).subscribe(
        response  => {
        if(response.userData){
        	this.Profile = response.userData;
      
        // 	console.log('Profile',this.Profile);
        	
        }else{
             console.log('User Data not found.');
        }
        //   console.log(response);
        },
          error => (
            console.log(error)
          )
    )
  }
  
  

    getMyTeamAllReviews_Data(){
      var form = new FormData();
      form.append("userId",this.userData.id);
      form.append("role",this.userData.roleId);
      form.append("companyId",this.userData.companyId);
     
  		let response = this.apiService.getMyTeamAllReviews(form).subscribe(
        response  => {
        if(response.myTeamUserPRData){
            for (var val of response.myTeamUserPRData) {
                for(var val1 of val.userPRData){
                    if(val1.status !== null){
                        this.MyTeamAllReviews.push(val1);
                    }
                }
            }
        // 	this.MyTeamAllReviews = response.myTeamUserPRData ;
        // 	console.log('MyTeamAllReviews',this.MyTeamAllReviews);
        
        }
        // else{
        //     this.MyTeamAllReviews = [];
        // }
        //   console.log("MyTeam" , response);
        },
          error => (
            console.log(error)
          )
    )
  }
  
   getMyAllReviews(){
      var form = new FormData();
      form.append("userId",this.userData.id);
      form.append("role",'4');
    	form.append("companyId", this.userData.companyId);
  		let response = this.apiService.getMyAllReviews(form).subscribe(
        response  => {
        if(response.userPRData){
        	this.PReviewAllMyData = response.userPRData;
        	var i =0;
        	var j =0;
        	var labels = [];
        	var datasets = [];
        	for(var prs of response.userPRData){
               if(prs.reviewStatus_employee == 1){
                   i++;
               }else{
                   j++;
               }   
            }
            this.prsCompleted = i;
            this.prsInProgress = j;
            this.prs = response.prs;
        	this.trendChart = response.trendChart;
            this.data = {
                labels: this.prs,
                datasets: this.trendChart
            }
            // this.data = {
            //     labels: labels,
            //     datasets: datasets
            // }
        // 	console.log('PReviewAllData',this.PReviewAllData);
        }
        //   console.log(response);
        },
          error => (
            console.log(error)
          )
    )
  }
  
 getAllGoals_ofEmployee_Data(){
      var form = new FormData();
      form.append("employeeId",this.userData.id);
     
  		let response = this.apiService.getAllGoals_ofEmployee(form).subscribe(
        response  => {
        if(response.goalDetails){
        	this.MyGoalsData = response.goalDetails;
        	var i =0;
        	var j =0;
        	for(var goals of response.goalDetails){
               if(goals.percCompleted == 100){
                   i++;
               }else{
                   j++;
               }   
            }
            this.goalsCompleted = i;
            this.goalsInProgress = j;
        // 	console.log('MyGoalsData',this.MyGoalsData);
        	
        // 	console.log('checkMyGoalsData',this.MyGoalsData[0].title);
        }
        //   console.log(response);
        },
          error => (
            console.log(error)
          )
    )
  }
  
   viewReviewEmployee(reviewId: number,employeeId: number,pruID: number) {
    //   console.log('viewReview',reviewId);
      localStorage.setItem('viewReviewId',JSON.stringify(reviewId));
      localStorage.setItem('viewReviewEmployeeId',JSON.stringify(employeeId));
      localStorage.setItem('pruIDView',JSON.stringify(pruID));
  this.router.navigate(['employee/performance-review-response-employee']);
}

}
