<div id="__next" *ngIf="!showReviewData">
    <div class="banner-section">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="banner-content">
                        <h1 *ngIf="prData && prData.length > 0">{{prData[0].title}}</h1>
                        <i *ngIf="prData && prData.length > 0 && prData[0].deadlineDate_employee != null">Due on {{prData[0].deadlineDate_employee  |  date : "mediumDate"}}</i>
                        <i *ngIf="prData && prData.length > 0 && prData[0].deadlineDate_employee == null">Due on -</i>
                        <i>Review Manager - Geraid Themas</i>
                        <a class="default-btn"
                            (click)="showReview()"><span class="fa fa-long-arrow-right"></span> Get Started </a>

                            <p>Your responses will be kept confidential and available for feedback review to your reporting manager and HR Administrator. If you have any question you can write too.</p>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="banner-image"><img src="./assets/images/banner-img1.png" alt="image">
                        <div class="banner-shape4"><img src="./assets/images/banner-shape4.png" alt="image"></div>
                        <div class="banner-shape5"><img src="./assets/images/banner-shape5.png" alt="image"></div>
                        <div class="banner-shape6"><img src="./assets/images/banner-shape6.png" alt="image"></div>
                        <div class="banner-shape7"><img src="./assets/images/banner-shape7.png" alt="image"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showReviewData">
<div class="intro py-3 bg-white text-center" >
    <div class="container">
        <h2 class="my-4">{{prData.title}}</h2>
    </div>
</div>
<div class="result py-3 d-none bg-light text-center" >
    <div class="container lead">
        <p>Question 1/10 </p>
    </div>
</div>


<div class="quiz py-4">
    <div class="container perf_review_fix--container">
        <!-- <h2 class="my-5 text-white">Category</h2> -->

        <div class="question_list perf_review_fix--questionlist">
        <div  *ngFor="let category of uniqueCategory; let i = index" [attr.data-index]="i">
            <div *ngIf="category != ''">
            <div class="cmu_tags">
              <a href="#" >Question Category</a> <h2>{{category}}</h2>
            </div>
            
                  <form [formGroup]="provideAnswerForm" class="quiz-form">
            <div class="my-5" formArrayName="userAnswers" *ngFor="let questions of questionsData; let i = index" [attr.data-index]="i"  >
                <div [formGroupName]="i">
                        <div  *ngIf="i < maxQuestionsCount && i >= minQuestionsCount && questions.categoryName == category">
                    <p class="lead font-weight-normal">
                        {{i+1}}. {{questions.QuestionText}}
                    </p>
                    <div *ngIf="questions.answerType == 1">
                        <div class="form-check my-2" >
                           <div class="rating-bar">
                              <div class="starsecone">
                                <div   class="
                                      starrating
                                      risingstar
                                      d-flex
                                      justify-content-end
                                      flex-row-reverse
                                    ">
                                    <ng-container *ngFor="let answerTextData of questions.answers.slice().reverse() ">
                                    <input type="radio" formControlName="answerId" value="{{answerTextData.answerText}}" id="{{answerTextData.questionId}}star{{answerTextData.answerId}}"/>
                                    <label for="{{answerTextData.questionId}}star{{answerTextData.answerId}}"
                                     ></label>
                                     </ng-container>
                                  <!--<input type="radio" formControlName="answerId" id="star5" value="1" /><label for="star5"-->
                                  <!--  title="5 star" ></label>-->
                                  <!--<input type="radio" formControlName="answerId" id="star4"  value="2" /><label for="star4"-->
                                  <!--  title="4 star"></label>-->
                                  <!--<input type="radio" formControlName="answerId" checked="" id="star3"  value="3" /><label for="star3"-->
                                  <!--  title="3 star"></label>-->
                                  <!--<input type="radio" formControlName="answerId" id="star2" value="4" /><label for="star2"-->
                                  <!--  title="2 star"></label>-->
                                  <!--<input type="radio" formControlName="answerId" id="star1"  value="5" /><label for="star1"-->
                                  <!--  title="1 star"></label>-->
                                </div>
                                <!--<p>Strongly Agree</p>-->
                              </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="questions.answerType == 2">
                        <div class="form-check my-2" *ngFor="let answerTextData of questions.answers; let j = index" [attr.data-index]="j">
                            <input  value="{{answerTextData.answerId}}" type="radio" class="mr-3" formControlName="answerId"  checked >
                            <label for="A">{{answerTextData.answerText}}</label>
                        </div>
                      <!--  <div *ngIf="submitted && f.answers.errors" class="invalid-feedback">-->
                      <!--      <div *ngIf="f.answers.errors.required">Answer is required</div>-->
                      <!--</div>-->
                    </div>
                    <div *ngIf="questions.answerType == 3">
                        <div class="form-check my-2" >
                           <textarea name="" id="" cols="30" rows="5" class="form-control"  placeholder="Enter cmment here" formControlName="answerId" ></textarea>
                      <!--     <div *ngIf="submitted && f.answers.errors" class="invalid-feedback">-->
                      <!--      <div *ngIf="f.answers.errors.required">Answer is required</div>-->
                      <!--</div>-->
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
        </form>
        </div>
        </div>
        <div class="content-section implementation ">
                   <p-paginator [rows]="10" [totalRecords]="totalQuestionCount" [first]="pageNumber"  (onPageChange)="paginate($event)" ></p-paginator>
            </div>
            <div class="text-center py-5 border-top">
                    <input *ngIf="maxQuestionsCount < totalQuestionCount  " type="button" class="default-btn mr-3" value="Next" (click)="nextButton()">
                    <input type="submit" class="default-btn" value="submit" (click)="onSubmit()">
                </div>
        <div class="clearfix"></div>

  
       

      </div>
    </div>
    </div>
</div>