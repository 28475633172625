import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../common-modules/services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser'
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import {PaginatorModule} from 'primeng/paginator';

@Component({
  selector: 'app-performance-review-response-employee',
  templateUrl: './performance-review-response-employee.component.html',
  styleUrls: []
})
export class PerformanceReviewResponseEmployeeeComponent implements OnInit {
provideReviewId:any = '';
 userData:any =[];
 prData:any = [];
 ratingData:any = 2;
 uniqueCategory:any = [];
 questionsData:any = [];
 minQuestionsCount:any = 0;
 maxQuestionsCount:any = 9;
 totalQuestionCount:any = 0;
 pageNumber: any = 1;
 submitted:any = false;
 questions:FormArray;
 showReviewData=false;
 pruIDView = '';
 viewReviewEmployeeId = '';
 constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService,
              private formBuilder: FormBuilder) { }
  ngOnInit(): void {
    if (localStorage.getItem("userData") === null) {
      this.router.navigate(['login']);
    }else{
      this.userData = JSON.parse(localStorage.getItem("userData"));
    }
    if (localStorage.getItem("viewReviewId") === null) {
      this.router.navigate(['performance-review']);
    }else{
      this.provideReviewId = JSON.parse(localStorage.getItem("viewReviewId"));
    }
    if (localStorage.getItem("viewReviewEmployeeId") !== null) {
      this.viewReviewEmployeeId = JSON.parse(localStorage.getItem("viewReviewEmployeeId"));
    }
    if (localStorage.getItem("pruIDView") !== null) {
      this.pruIDView = JSON.parse(localStorage.getItem("pruIDView"));
    }else{
      this.router.navigate(['performance-review']);
    }
    this.checkUserEligibleForPR();
  }
  
  
  paginate(event){
    //   console.log('first',event.first);
    //   console.log('rows',event.rows);
    //   console.log('page',event.page);
    //   console.log('pageCount ',event.pageCount );
      this.pageNumber = event.first;
      
      this.minQuestionsCount = event.first;
      this.maxQuestionsCount = event.first + 10;
  }

  checkUserEligibleForPR(){
        var form = new FormData();
        form.append("userId",this.userData.id);
        form.append("role",this.userData.roleId);
        form.append("reviewId",this.provideReviewId);
        form.append("companyId", this.userData.companyId);
  		let response = this.apiService.checkUserReviewEligibleForToPR(form).subscribe(
            response  => {
            if(response.valid == 1){
            	this.getPerformanceReviewsQuestions();
            }else{
                alert('You are not eligible for performance review.');
            }
            //   console.log(response);
            },
              error => (
                console.log(error)
              )
        )
  }
  getPerformanceReviewsQuestions(){
        var form = new FormData();
        form.append("reviewId",this.provideReviewId);
        form.append("reviewUsersId",this.pruIDView);
        // form.append("companyId",this.userData.companyId);
        // form.append("userId",this.userData.id);
        // if(this.userData.roleId == 3 ){
        //     form.append("companyId",this.userData.companyId);
        //     form.append("userId",this.userData.id);
      		// let response = this.apiService.getPerformanecReview_SubmittedQuestionAnswersForManager(form).subscribe(
        //         response  => {
        //         if(response.prData){
        //             if(response.questionsData){
        //                 this.prData = response.prData;
        //                 this.questionsData = response.questionsData;
        //                 console.log(this.questionsData);
        //         	    this.uniqueCategory = [...new Set(this.questionsData.map(item => item.categoryName))];
        //         	    this.totalQuestionCount = this.questionsData.length;
        //             }else{
        //                 alert('There is no question added in Performance review by HR.');
        //             }
        //         }else{
        //             alert('There is no performance review.');
        //         }
        //           console.log(response);
        //         },
        //           error => (
        //             console.log(error)
        //           )
        //     )    
        // }else{
            form.append("companyId",this.userData.companyId);
            // console.log(this.viewReviewEmployeeId);
            if(this.viewReviewEmployeeId !== '' && this.viewReviewEmployeeId !== 'undefined'){
                form.append("userId",this.viewReviewEmployeeId);
            }else{
                form.append("userId",this.userData.id);
            }
            let response = this.apiService.getPerformanecReview_SubmittedQuestionAnswersForEmployee(form).subscribe(
                response  => {
                if(response.prData){
                    if(response.questionsData){
                        this.prData = response.prData;
                        this.questionsData = response.questionsData;
                        // console.log(this.questionsData);
                	    this.uniqueCategory = [...new Set(this.questionsData.map(item => item.categoryName))];
                	    this.totalQuestionCount = this.questionsData.length;
                	    
                    }else{
                        alert('There is no question added in Performance review by HR.');
                    }
                }else{
                    alert('There is no performance review.');
                }
                //   console.log(response);
                },
                  error => (
                    console.log(error)
                  )
            )  
        // }
  }
  
    nextButton(){
      this.minQuestionsCount = this.minQuestionsCount + 10;
      this.maxQuestionsCount = this.maxQuestionsCount + 10;
    }
}