import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../common-modules/services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-my-team--performance-review',
  templateUrl: './my-team-performance-review.component.html',
  styleUrls: []
})
export class MyTeamPerformanceReviewComponent  implements OnInit {
PReviewMyTeamData = [];
userData:any = [];
userId = '';
constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService) { }
  ngOnInit(): void {
    if (localStorage.getItem("userData") === null) {
      this.router.navigate(['login']);
    }else{
      this.userData = JSON.parse(localStorage.getItem("userData"));
      this.userId = this.userData.id;
    }
      this.getMyTeamAllReviews();
  }
  
  getMyTeamAllReviews(){
      var form = new FormData();
      form.append("userId",this.userData.id);
      form.append("role",this.userData.roleId);
    	form.append("companyId", this.userData.companyId);
  		let response = this.apiService.getMyTeamAllReviews(form).subscribe(
        response  => {
        if(response.myTeamUserPRData){
        	this.PReviewMyTeamData = response.myTeamUserPRData;
        // 	console.log('PReviewMyTeamData',this.PReviewMyTeamData);
        }
        //   console.log(response);
        },
          error => (
            console.log(error)
          )
    )
  }
  
  provideReview(reviewId: number,pruID: number) {
    // console.log('provideReview',reviewId);
    localStorage.setItem('provideReviewId',JSON.stringify(reviewId));
    localStorage.setItem('pruID',JSON.stringify(pruID));
    localStorage.setItem('selfOrTeam',JSON.stringify(1));
  this.router.navigate(['employee/provide-performance-review']);
}
  
  
  viewReview(reviewId: number,employeeId: number,pruID: number) {
    //   console.log('viewReview',reviewId);
      localStorage.setItem('viewReviewId',JSON.stringify(reviewId));
      localStorage.setItem('viewReviewEmployeeId',JSON.stringify(employeeId));
      localStorage.setItem('pruIDView',JSON.stringify(pruID));
  this.router.navigate(['employee/performance-review-response']);
}

 viewReviewEmployee(reviewId: number,employeeId: number,pruID: number) {
    //   console.log('viewReview',reviewId);
      localStorage.setItem('viewReviewId',JSON.stringify(reviewId));
      localStorage.setItem('viewReviewEmployeeId',JSON.stringify(employeeId));
      localStorage.setItem('pruIDView',JSON.stringify(pruID));
  this.router.navigate(['employee/performance-review-response-employee']);
}
  
}