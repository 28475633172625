<!DOCTYPE html>
<html lang="en">
<head>
  <title>Goal</title>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <link rel="shortcut icon" href=".././assets/images/favicon.ico" type="image/x-icon">

  @import "bootstrap.css";
  @import "daterangepicker.css";
  @import "bootstrap-select.min.css";
  @import "style.css";
  @import "all.css";
  @import "font-awesome.min.css";
  <script src="./assets/js/jquery.min.js"></script>
  <script src="./assets/js/popper.min.js"></script>
  <script src="./assets/js/bootstrap.min.js"></script>
  <script type="text/javascript" src="https://www.google.com/jsapi"></script>
</head>
<body>

  <nav class="navbar navbar-expand-sm fixed-top bg-dark navbar-dark py-0">
    <!-- Brand -->
    <a class="navbar-brand" routerLink="/employee/dashboard"><img *ngIf="!companyLogo" src="./assets/images/Aestimo_15_final.png" class="img-fluid" />
    <img *ngIf="companyLogo" src="{{companyLogo }}" class="img-fluid" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Links -->
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav mx-5">
        <!-- Dropdown -->
        <li class="nav-item" routerLinkActive="active"
            [routerLinkActiveOptions]={exact:true}>
          <a class="nav-link" routerLink="/employee/dashboard" routerLinkActive="active" [routerLinkActiveOptions]={exact:true}>Home</a>
        </li>
        <li class="nav-item dropdown" routerLinkActive="active">
          <a class="nav-link dropdown-toggle" id="navbardrop" data-toggle="dropdown" routerLinkActive="active">
            Performance
          </a>
          <div class="dropdown-menu" routerLinkActive="active">
            <a class="dropdown-item" routerLink="/employee/performance-review" *ngIf="roleId == '3'  || roleId == '4'" routerLinkActive="active">My Reviews</a>
            <a class="dropdown-item" routerLink="/employee/my-team-performance-review" *ngIf="roleId == '3'" routerLinkActive="active">Team</a>
          </div>
        </li>
        
        <li class="nav-item dropdown" routerLinkActive="active">
          <a class="nav-link dropdown-toggle" id="navbardrop" data-toggle="dropdown" routerLinkActive="active">
            Goals
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" routerLink="/employee/goals" *ngIf="roleId == '3'  || roleId == '4'">My Goals</a>
            <a class="dropdown-item" routerLink="/employee/my-team-goals" *ngIf="roleId == '3' ">Team</a>
          </div>
        </li>
        
        <!--<li class="nav-item" routerLinkActive="active">-->
        <!--  <a class="nav-link" routerLink="/employee/report" routerLinkActive="active">Reports</a>-->
        <!--</li>-->
      </ul>
    <div class="ctm-dropdown for-desk">
          <div class="nav-item dropdown float-left text-white notification">
            <a class="nav-link dropdown-toggle text-white" href="#" id="navbardrop" data-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-bell"></i>
              <span class="count">{{notificationsLength}}</span>
            </a>
            <div class="dropdown-menu">
                <div class="dropdown-item noti-title">
                    <h5 class="m-0">
                        <span class="float-end">
                            <a (click)="clearAll()" class="text-dark">
                                <small>Clear All</small>
                            </a>
                        </span>Notification
                    </h5>
                </div>
                <div class="noti-scroll">
                    <a class="dropdown-item" *ngFor="let notification of notifications">
                      <div class="notification-sec d-flex align-items-center justify-content-between" (click)="clear(notification.id)">
                        <div class="noti-cnt">
                          <h5>{{notification.title}}</h5>
                          <small class="text-muted">{{notification.message}}</small>
                        </div>
                      </div>
                    </a>
                 </div>
                <!--<div class="noti-footer">-->
                <!--     <a href="#">View All</a>-->
                <!--</div>-->
            </div>
          </div>
         
         
         
         
          <div class="nav-item dropdown float-left text-white profile_menu">
            <a class="nav-link dropdown-toggle text-white" href="#" id="navbardrop" data-toggle="dropdown"
              aria-expanded="false">
              <i class="fas fa-user"></i> Me
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" routerLink="profile">Profile</a>
              <a class="dropdown-item" routerLink="change-password">Change Password</a>
              <a class="dropdown-item" (click)="logout()">Logout</a>
            </div>
          </div>
    
    </div>
    </div>
  </nav>