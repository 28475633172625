import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EmployeemanagerRoutingModule } from './employeemanager-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GoalsComponent } from './goals/goals.component';
import { PerformanceReviewComponent } from './performance-review/performance-review.component';
import { EmployeemanagerLayoutComponent } from '../../core-components/employeemanager/employeemanager-layout/employeemanager-layout.component';
import { HeaderComponent } from '../../core-components/employeemanager/header/header.component';
import { FooterComponent } from '../../core-components/employeemanager/footer/footer.component';
import { AssignGoalComponent } from './goals/assigngoal.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './changepassword/changepassword.component';
import { BrowserModule } from '@angular/platform-browser'
import { MyTeamPerformanceReviewComponent } from './performance-review/my-team-performance-review.component';
import { ProvidePerformanceReviewComponent } from './performance-review/provide-performance-review.component';
import { PerformanceReviewResponseComponent } from './performance-review/performance-review-response.component';
import {TableModule} from 'primeng/table';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { MyTeamGoalsComponent } from './goals/my-team-goals.component';
import { PerformanceReviewResponseEmployeeeComponent  } from './performance-review/performance-review-response-employee.component';
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { ProvidePerformanceReviewSubmissionComponent  } from './performance-review/provide-review-submission.component';

import {PaginatorModule} from 'primeng/paginator';
// import {RatingModule} from 'primeng/rating';
// import {SliderModule} from 'primeng/slider';
// import {ChartModule} from 'primeng/chart';
import {ChartModule} from 'primeng/chart';


@NgModule({
  declarations: [
    DashboardComponent,
    GoalsComponent,
    PerformanceReviewComponent,
    EmployeemanagerLayoutComponent,
    HeaderComponent,
    FooterComponent,
    AssignGoalComponent,
    ProfileComponent,
    ChangePasswordComponent,
    MyTeamPerformanceReviewComponent,
    MyTeamGoalsComponent,
    ProvidePerformanceReviewComponent,
    PerformanceReviewResponseComponent,
    PerformanceReviewResponseEmployeeeComponent,
    ProvidePerformanceReviewSubmissionComponent
  ],
  imports: [
    CommonModule,
    EmployeemanagerRoutingModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    TableModule,
    ReactiveFormsModule,
    PaginatorModule,
    ChartModule,

    // AngularEditorModule
    // ChartModule
    // ChartModule
    // SliderModule
    // RatingModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class EmployeemanagerModule { }
