import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  baseURL: string = environment.apiUrl;

  //email-management
   getAllEmailTemplateOfCompany(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-email-Template-of-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   getEmailTemplateDataBasedOnId(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-email-Template-data-based-on-templateId", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   addOrUpdateEmailTemplateOfCompany(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "add-update-email-Template-of-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  //goal
  
  getGoalDetails(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-goal-details", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   getAllGoalDetailsBasedOnCompany(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-goal-details-by-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   addGoalDetails(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "add-goal-details", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   updateGoalDetails(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "update-goal-details", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
   deleteGoalDetails(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "delete-goal-details", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   getGoalDetails_ofEmployee(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-employee-goal-details", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 

   getMyGoalDetails(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-my-goal-details", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 

   updateEmployeeGoalPercentage(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "update-employee-goal-percentage", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   asignEmployeeGoal(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "add-employee-goal", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   romoveEmployeeGoal(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "remove-employee-goal", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   updateEmployeeCommentInGoal(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "update-employee-comment-in-goal", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   addEmployeeCommentInGoal(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "add-employee-comment-in-goal", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   removeEmployeeCommentInGoal(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "remove-employee-comment-in-goal", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 

   getAllGoals_ofEmployee(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-goals-of-employee", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 

   getAllGoals_ofMyTeam(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-goals-of-myteam", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 

   getAllPastGoals_ofEmployee(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-past-goals-of-employee", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 

   getAllActiveGoals_ofEmployee(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-active-goals-of-employee", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 
   
   getGoalStatsByGrade(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-goal-stats-by-grade", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 

   //grade
   getAllGradeOfCompany(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-grades-of-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  insertGradeDetails(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "insert-grade-for-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
  updateGradeDetails(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "update-grade-for-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
  setActiveInactiveForGrade(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "set-active-inactivate-grade-for-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }   

   //job-profile
    getAllJobProfileOfCompany(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-jobprofile-of-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  insertJobProfileDetails(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "insert-jobprofile-for-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
  updateJobProfileDetails(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "update-jobprofile-for-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
  setActiveInactiveForJobProfile(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "set-active-inactivate-jobprofile-for-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  

   //performance
  createPerformanceReview_addSetting(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "create-pr-setting", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   createPerformanceReview_AddQuestionForEmployee(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "create-pr-add-question-employee", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  createPerformanceReview_AddQuestionForManager(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "create-pr-add-question-manager", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   createPerformanceReview_AddUserToPR(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "create-pr-add-user", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  getPerformanceReview_Setting(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-pr-reviews", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   createPerformanceReview_Schedule(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "create-pr-schedule", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  userPerformanceReview_Submission(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "add-user-pr-submission", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   getPerformanecReview_QuestionsForManager(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-manager-pr-questions", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  getPerformanecReview_QuestionsForEmployee(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-employee-pr-questions", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   editPerformanceReview_Title(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "edit-pr-title", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  editPerformanceReview_Schedule(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "edit-pr-schedule", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   changePerformanceReview_Cycle(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "change-pr-cycle", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   changePerformanceReview_changeQuestionOrderForEmployee(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "change-pr-question-order-for-employee", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   changePerformanceReview_changeQuestionOrderForManager(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "change-pr-question-order-for-manager", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
   changePerformanceReview_removeUserFromPR(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "remove-users-from-pr", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   getPerformanceReview_UsersFromPR(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-users-from-pr", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
   addPerformanceMatrixMaster(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "add-performance-matrix-master", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   getPerformanceMatrixMaster(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-performance-matrix-master", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
   removePerformanceMatrixMaster(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "remove-performance-matrix-master", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   editPerformanceMatrixMaster(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "edit-performance-matrix-master", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
   submitPerformanceReview(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "submit-user-pr", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   checkUserReviewEligibleForToPR(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "check-user-eligible-for-pr", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
   changeStartDateUserReviewPR(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "change-user-pr-start-date", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   getMyAllReviews(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-my-all-reviews", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
   getMyTeamAllReviews(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-my-team-all-reviews", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
   getPerformanecReview_SubmittedQuestionAnswersForEmployee(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-employee-submitted-pr-questions-answer", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
   getPerformanecReview_SubmittedQuestionAnswersForManager(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-manager-submitted-pr-questions-answer", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
   getPerformanecReview_QuestionsForManagerForEdit(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-manager-pr-questions-for-edit", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  getPerformanecReview_QuestionsForEmployeeForEdit(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-employee-pr-questions-for-edit", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
  getAppraisalReportData(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-appraisal-report-data", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  
   
  addAppraisalCalculation(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "add-appraisal-calculation", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }     
   
  getAllAppraisalGroupByCompanyIdYear(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-appraisal-group-by-company-year", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }        
   
  getAllAppraisalCalulationsofGroup(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-appraisal-calculation-by-group", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }           
   
  updateManagerInPerformanceReviewOfUser(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "change-manager-in-performance-review-of-user", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }         
   
 generateReportForPerformanceReview(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "generate-report-for-performance-review", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }         
   
   
   //plan

  getAllMasterPlans(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-master-plans", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  
  getAllActivePlanCompanies(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-active-plan-companies", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  

  getAllExpiredPlanCompanies(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-expired-plan-companies", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 

  changePlanStatus(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "change_plan-status", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  

  getPlanHistoryOfCompany(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-plan-history-of-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 
   
  deletePlan(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "delete-plan", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  

  createPlan(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "create-plan", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 
   
  updatePlan(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "update-plan", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  

  checkIfPlanExpiredOfCompany(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "check-if-plan-expired-of-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 
   
  assignFirstPlanToCompany(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "assign_first_plan_to_company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  

  makePaymentOfInvoice(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "make-payment-of-invoice", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 

  getCompanyPlanDetails(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-company-plan-details", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  

  renewPlanOfCompany(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "renew-plan-of-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 
   
  getExpiredPlanInvoiceDetailsOfCompany(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-expired-plan-invoice-of-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  
   
  removeEmployeeFromPR(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "remove-employee-from-pr", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 
   
  getAllCompaniesWithTherePlan(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-all-companies-with-plan", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  } 
   
  getPaymentSettings(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "get-payment-settings", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }
  
  updatePaymentSettings(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "update-payment-settings", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }
  
  renewPlanOfCompanyBySuperAdmin(object: any): Observable<any> {
	 return this.http.post(this.baseURL + "renew-plan-of-company-by-superadmin", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }

//question
     getAllQuestionAnswer(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "get-all-question-answer", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

   addQuestionCategrory(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "add-question-category", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  

   getAllQuestionCategoriesByCompanyId(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "get-all-question-categories", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  

   addQuestionAnswer(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "add-question-answer", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  

   editQuestionAnswer(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "edit-question-answer", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 

   deleteQuestion(object: any): Observable<any> {
	  return this.http.post(this.baseURL + "delete-question", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  

   deleteAnswer(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "delete-answer", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 

   cloneAdminQuestionToCompanyQuestionBank(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "clone-admin-question-to-company-question-bank", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  setStatusOfQuestion(object: any): Observable<any> {
     return this.http.post(this.baseURL + "set-status-of-question", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }    
    
   getAllQuestionCategoriesWithQuestionCountByCompanyId(object: any): Observable<any> {
     return this.http.post(this.baseURL + "get-all-question-categories-with-question-count", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  
   
   editQuestionCategrory(object: any): Observable<any> {
     return this.http.post(this.baseURL + "edit-question-category", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   } 
   
   deleteQuestionCategrory(object: any): Observable<any> {
     return this.http.post(this.baseURL + "delete-question-category", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }  
   
   updateQuestionText(object: any): Observable<any> {
     return this.http.post(this.baseURL + "change-question-text", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
   getAllQuestionAnswerOfAdminForClone(object: any): Observable<any> {
     return this.http.post(this.baseURL + "get-all-question-answer-of-admin-for-clone", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }     
   

   //terms
  updateOrInsertTerm(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "update-or-insert-term", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  checkUserHasacceptedTerms(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "check-user-has-accepted-terms", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  acceptUserTerms(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "accept-user-terms", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }      

  getAllTerms(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "get-all-terms", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }

  addTerm(object: any): Observable<any> {
	   return this.http.post(this.baseURL + "add-term", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
   }
   
  //user
  login(object: any) {
    //console.log('userData',user);
    return this.http.post(this.baseURL + "login", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }

 signupStep1(object: any): Observable<any> {
   return this.http.post(this.baseURL + "signup-step1", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  } 

 signup(object: any): Observable<any> {
   return this.http.post(this.baseURL + "signup", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  } 

 getUserDetails(object: any): Observable<any> {
   return this.http.post(this.baseURL + "get-user-details", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  } 

 getAllRoles(object: any): Observable<any> {
   return this.http.post(this.baseURL + "get-all-roles", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  } 

 getUserRole(object: any): Observable<any> {
   return this.http.post(this.baseURL + "get-role-of-user", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }   

 getEmployeeOfCompany(object: any): Observable<any> {
   return this.http.post(this.baseURL + "get-employee-of-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }  

 bulkUploadUsersOfCompany(object: any): Observable<any> {
   return this.http.post(this.baseURL + "bulk-upload-users-of-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  } 
  
 importSetArray(object: any): Observable<any> {
   return this.http.post(this.baseURL + "import-set-array-bulk-upload-users-of-company", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }  

 addCompanyLogo(object: any): Observable<any> {
   return this.http.post(this.baseURL + "add-company-logo", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  } 
  
 addEmployeeProfile(object: any): Observable<any> {
   return this.http.post(this.baseURL + "add-employee-profile", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }  
  
 changePassword(object: any): Observable<any> {
   return this.http.post(this.baseURL + "change-password", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }  
 
 getMyTeam(object: any): Observable<any> {
   return this.http.post(this.baseURL + "get-my-team", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }   
  
 checkEmployeeAdded(object: any): Observable<any> {
   return this.http.post(this.baseURL + "check-employee-added", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }   
  
 getBulkUploadedUserList(object: any): Observable<any> {
   return this.http.post(this.baseURL + "get-bulk-upload-user-list", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }     
  
  getCompanyDetailsBasedOnCompanyId(object: any): Observable<any> {
   return this.http.post(this.baseURL + "get-company-details-based-on-companyId", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }    
  
  updateCompanyDetails(object: any): Observable<any> {
   return this.http.post(this.baseURL + "change-company-details", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }    
  
  HRAdminDashboardData(object: any): Observable<any> {
   return this.http.post(this.baseURL + "hradmin-dashboard-data", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }    
  
//   HRAdminDashboardData(object: any): Observable<any> {
//   return this.http.post(this.baseURL + "hradmin-dashboard-data", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
//   }    
  
  SuperAdminDashboardData(object: any): Observable<any> {
   return this.http.post(this.baseURL + "superadmin-dashboard-data", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }
  
  payment(object: any): Observable<any> {
   return this.http.post(this.baseURL + "hit-payment", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }
  
  sendEmail(object: any): Observable<any> {
   return this.http.post(this.baseURL + "send-email", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }
  
  resendEmailForPerfomanceReview(object: any): Observable<any> {
   return this.http.post(this.baseURL + "resend-email-for-performance-review", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }
  
  DeactivateCompanyAccount(object: any): Observable<any> {
   return this.http.post(this.baseURL + "deactivate-company-account", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }
  
  ActivateCompanyAccount(object: any): Observable<any> {
   return this.http.post(this.baseURL + "activate-company-account", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }
  
  getUserNotification(object: any): Observable<any> {
   return this.http.post(this.baseURL + "get-user-notification", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }
  
  markReadUserNotification(object: any): Observable<any> {
   return this.http.post(this.baseURL + "mark-read-user-notification", object).pipe(map((res: any) => res),catchError((error: any) =>  throwError(error.error)));
  }
  

}
