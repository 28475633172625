import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../common-modules/services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser'
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import {PaginatorModule} from 'primeng/paginator';

@Component({
  selector: 'app-provide-performance-review',
  templateUrl: './provide-performance-review.component.html',
  styleUrls: ['./provide-performance-review.component.css']
})
export class ProvidePerformanceReviewComponent implements OnInit {
 provideReviewId:any = '';
 userData:any =[];
 prData:any = [];
 ratingData:any = 2;
 uniqueCategory:any = [];
 questionsData:any = [];
 minQuestionsCount:any = 0;
 maxQuestionsCount:any = 9;
 totalQuestionCount:any = 0;
 pageNumber: any = 1;
 provideAnswerForm: FormGroup;
 submitted:any = false;
 questions:FormArray;
 showReviewData=false;
 pruID:any = '';
 selfOrTeam:any = '';
 constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService,
              private formBuilder: FormBuilder) { }
  ngOnInit(): void {
    if (localStorage.getItem("userData") === null) {
      this.router.navigate(['login']);
    }else{
      this.userData = JSON.parse(localStorage.getItem("userData"));
    }
    if (localStorage.getItem("provideReviewId") === null) {
      this.router.navigate(['employee/performance-review']);
    }else{
      this.provideReviewId = JSON.parse(localStorage.getItem("provideReviewId"));
    }
    if (localStorage.getItem("pruID") === null) {
      this.router.navigate(['employee/performance-review']);
    }else{
      this.pruID = JSON.parse(localStorage.getItem("pruID"));
    }
    if (localStorage.getItem("selfOrTeam") === null) {
      this.router.navigate(['employee/performance-review']);
    }else{
      this.selfOrTeam = JSON.parse(localStorage.getItem("selfOrTeam"));
    }
    
    this.provideAnswerForm = this.formBuilder.group({
        userAnswers: this.formBuilder.array([])
    });
    this.checkUserEligibleForPR();
  }
  
  get userAnswer() {
    return this.provideAnswerForm.get("userAnswers") as FormArray;
  }
  
  get f() { return this.provideAnswerForm.controls; }
  
  showReview(){
      this.showReviewData = true;
    //   if(el){
    //     el.scrollIntoView({behavior: 'smooth'});
    //   }
  }
  
  paginate(event){
    //   console.log('first',event.first);
    //   console.log('rows',event.rows);
    //   console.log('page',event.page);
    //   console.log('pageCount ',event.pageCount );
      this.pageNumber = event.first;
      
      this.minQuestionsCount = event.first;
      this.maxQuestionsCount = event.first + 10;
  }

  checkUserEligibleForPR(){
        var form = new FormData();
        form.append("userId",this.userData.id);
        form.append("role",this.userData.roleId);
        form.append("reviewId",this.provideReviewId);
        form.append("companyId", this.userData.companyId);
  		let response = this.apiService.checkUserReviewEligibleForToPR(form).subscribe(
            response  => {
            if(response.valid == 1){
            	this.getPerformanceReviewsQuestions();
            }else{
                alert('You are not eligible for performance review.');
            }
            //   console.log(response);
            },
              error => (
                console.log(error)
              )
        )
  }
  getPerformanceReviewsQuestions(){
        var form = new FormData();
        form.append("reviewId",this.provideReviewId);
        // 
        if(this.userData.roleId == 3 && this.selfOrTeam == 1){
      		let response = this.apiService.getPerformanecReview_QuestionsForManager(form).subscribe(
                response  => {
                if(response.prData){
                    if(response.questionsData){
                        this.prData = response.prData;
                        // console.log('this.prData ',this.prData );
                        this.questionsData = response.questionsData;
                        this.questionsData.map(d =>
                              this.userAnswer.push(this.formBuilder.group({ questionId: d.questionId, answerId: "", answerType: d.answerType }))
                            );
                        // console.log(this.questionsData);
                	    this.uniqueCategory = [...new Set(this.questionsData.map(item => item.categoryName))];
                	    this.totalQuestionCount = this.questionsData.length;
                    }else{
                        alert('There is no question added in Performance review by HR.');
                    }
                }else{
                    alert('There is no performance review.');
                }
                //   console.log(response);
                },
                  error => (
                    console.log(error)
                  )
            )    
        }else{
            let response = this.apiService.getPerformanecReview_QuestionsForEmployee(form).subscribe(
                response  => {
                if(response.prData){
                    if(response.questionsData){
                        this.prData = response.prData;
                        //  console.log('this.prData ',this.prData );
                        this.questionsData = response.questionsData;
                        this.questionsData.map(d =>
                              this.userAnswer.push(this.formBuilder.group({ questionId: d.questionId, answerId: "", answerType: d.answerType }))
                            );
                        // console.log(this.questionsData);
                	    this.uniqueCategory = [...new Set(this.questionsData.map(item => item.categoryName))];
                	    this.totalQuestionCount = this.questionsData.length;
                    }else{
                        alert('There is no question added in Performance review by HR.');
                    }
                }else{
                    alert('There is no performance review.');
                }
                //   console.log(response);
                },
                  error => (
                    console.log(error)
                  )
            )  
        }
  }
  
   onSubmit() {
        this.submitted = true;
        // console.log(this.provideAnswerForm.get("userAnswers").value);
        // console.log('12354');
        // console.log(this.provideAnswerForm.invalid);
        // stop here if form is invalid
        if (this.provideAnswerForm.invalid) {
            return;
        }
        var form = new FormData();
        form.append("employeeId" , this.userData.id);
        //selfOrTeam == 1 for self , 2 for team
        if(this.selfOrTeam == "2"){
            form.append("submitByRole" , "4");
        }else{
            form.append("submitByRole" , "3");
        }
        // form.append("submitByRole" , this.userData.roleId);
        form.append("companyId" , this.userData.companyId);
        form.append("reviewId" , this.provideReviewId);
        form.append("managerId" , this.userData.managerId);
        form.append("updatedByUserId" , this.userData.id);
        form.append("pruID",this.pruID);
        form.append("flag" , "1");
        form.append("reviewData",JSON.stringify(this.provideAnswerForm.get("userAnswers").value));
        let response = this.apiService.submitPerformanceReview(form).subscribe(
            response  => {
              if(response.status == 1){
                  alert(response.message);
                   if(this.selfOrTeam == "2"){
                        this.router.navigate(['employee/performance-review']);
                   }else{
                       this.router.navigate(['employee/my-team-performance-review']);
                   }
                //   console.log(response);
              }else{
                  alert(response.message);
              	return false;
              }
            },
            error => {
                console.log(error);
                return false;
            }
    
        );
        
    }
    
    nextButton(){
      this.minQuestionsCount = this.minQuestionsCount + 10;
      this.maxQuestionsCount = this.maxQuestionsCount + 10;
    }
}