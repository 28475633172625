import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../common-modules/services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../../common-modules/services/must-match.validator';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: []
})
export class ChangePasswordComponent implements OnInit {
    
  changepasswordForm: FormGroup;
  submitted = false;
  oldPass = '';
  newPass = '';
  confirmPass = '';
  constructor(
        private router : Router,
        private formBuilder: FormBuilder,
        private apiService : ApiService) { }

  ngOnInit(): void {
      this.changepasswordForm = this.formBuilder.group({
            oldPass: ['', Validators.required],
            newPass: ['', [Validators.required, Validators.minLength(6)]],
            confirmPass: ['', Validators.required],
        
        }, {
            validator: MustMatch('newPass', 'confirmPass')
        });
  }
  
 get f() { return this.changepasswordForm.controls; }

 onSubmit() {
        this.submitted = true;
        // console.log('12354');
        // console.log(this.changepasswordForm.invalid);
        // stop here if form is invalid
        if (this.changepasswordForm.invalid) {
            return;
        }
        var form = new FormData();
        form.append("userId" , "2");
        form.append("password",this.changepasswordForm.value.oldPass);
        form.append("newPassword",this.changepasswordForm.value.newPass);
        form.append("confirmPassword",this.changepasswordForm.value.confirmPass);
        let response = this.apiService.changePassword(form).subscribe(
            response  => {
              if(response.status == 1){
                //   console.log(response);
                alert('Password changed successfully.');
              }else{
                  alert('Something went wrong.');
               	return false;
              }
            },
            error => {
                console.log(error);
                return false;
            }
    
        );
        
    }
}
