<!DOCTYPE html>
<html>
<head>
  <title>Aestimo Login</title>
  <meta name="viewport" content="width=device-width, initial-scale=1">

  <link href="https://fonts.googleapis.com/css?family=Poppins:600&display=swap" rel="stylesheet">
  <script src="https://kit.fontawesome.com/a81368914c.js"></script>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <!--<link rel="stylesheet" href="./assets/css/bootstrap.min.css">-->
  <!--  <link rel="stylesheet" href="./assets/css/font-awesome.min.css">-->
  <!--<link rel="stylesheet" type="text/css" href="./assets/css/login.css">-->



  
    <script src="./assets/js/jquery.min.js"></script>
  <script src="./assets/js/popper.min.js"></script>
  <script src="./assets/js/bootstrap.min.js"></script>


</head>

<body>
    
    <p-toast position="bottom-center"  styleClass="custom-toast" ></p-toast>

  <img class="wave" src="./assets/images/wave-1.png">
  <div class="container-login">
    <div class="img">
      <img src="./assets/images/avtar-5.png">
    </div>
    <div class="login-content">
        <form id="loginForm" [formGroup]="form" (ngSubmit)="doLogin()">
          <img src="./assets/images/Aestimo_logo.png" class="login_logo" height="60px;">     
              <div class="input-div one" [ngClass]="{ 'focus' : isFocussed(1) || form.controls.email.dirty}">
                 <div class="i">
                    <i class="fas fa-user"></i>
                 </div>
                 <div class="div">
                    <h5>Username</h5>
                    <input type="email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && form.controls.email.errors }"  class="input" (focus)="addBorder(1)" (blur)="removeBorder()" >
                    <div *ngIf="submitted && form.controls.email.errors" class="invalid-feedback">
                        <div *ngIf="form.controls.email.errors.required">Username is required</div>
                    </div>
                 </div>
              </div>
              <div class="input-div pass" [ngClass]="{ 'focus' : isFocussed(2) || form.controls.password.dirty}">
                 <div class="i"> 
                    <i class="fas fa-lock"></i>
                 </div>
                 <div class="div">
                    <h5>Password</h5>
                    <input type="password" name="password" class="input"  formControlName="password" [ngClass]="{ 'is-invalid': submitted && form.controls.password.errors }" (focus)="addBorder(2)" (blur)="removeBorder()">
                    <div *ngIf="submitted && form.controls.password.errors" class="invalid-feedback">
                        <div *ngIf="form.controls.password.errors.required">Password is required</div>
                    </div>
                 </div>
              </div>
              <button  type="submit"  class="login-btn">Login</button>
        </form>
       
    </div>
      
    </div>


  <script src="./assets/js/main.js"></script>
</body>
</html>