import { Injectable, Injector } from "@angular/core";
import {
    HttpErrorResponse,
    HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";

import {map, tap, catchError} from 'rxjs/operators'
import {Router} from '@angular/router'
import {Observable, pipe, throwError} from "rxjs";
@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private router:Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
    let token = JSON.parse(localStorage.getItem("token"));
    if (token) {
         req = req.clone({
          setHeaders: {
            token: `Bearer ${token}`
          },
        });
    }
    
    return next.handle(req)
    .pipe(tap(event=>{}),catchError((error:HttpErrorResponse)=>{
        if(error.status == 401 || error.message == "Access denied"){
            localStorage.clear()
            this.router.navigate(["/login"]);
        }
            return throwError(error)
    }))
    
  }
}
