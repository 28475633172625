import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../common-modules/services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-assigngoal',
  templateUrl: './assigngoal.component.html',
  styleUrls: []
})
export class AssignGoalComponent implements OnInit {
  step: any =1;
  createGoalForm: FormGroup;
  submitted = false;
  title = '';
  descriptionData = '';
  priority = '';
  dueDate = '';
  goalSubmitView = '';
  employeeList = [];
  colsForEmployeeList = [];
  selectedEmployees = [];   
  goalId: any;
  userData: any;
  employeeSelectionMessage = '';
  
  constructor(private route: ActivatedRoute,
              private router: Router,
                      private formBuilder: FormBuilder,

              private apiService: ApiService) { }
  ngOnInit(): void {
      
      if (localStorage.getItem("userData") === null) {
         this.router.navigate(['login']);
      }else{
         this.userData = JSON.parse(localStorage.getItem("userData"));
      }
      this.createGoalForm = this.formBuilder.group({
            title: ['', Validators.required],
            descriptionData: ['', Validators.required],
            priority: ['', Validators.required],
            dueDate: ['', Validators.required],
            goalSubmitView: ['', Validators.required]
        });
        
      this.colsForEmployeeList = [
  		{ field: 'firstName', header: 'Members Name' },
  		{ field: 'gradeName', header: 'Grade' },
        { field: 'jobName', header: 'Department' },
        { field: 'Start Date', header: 'Start Date' },
      ]; 
  }
  
    get f() { return this.createGoalForm.controls; }

  onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.createGoalForm.invalid) {
            return;
        }
        var form = new FormData();
        form.append("userId" , this.userData.id);
        form.append("companyId" , this.userData.companyId);
        form.append("title",this.createGoalForm.value.title);
        form.append("description",this.createGoalForm.value.descriptionData);
        form.append("priority",this.createGoalForm.value.priority);
        form.append("dueDate",this.createGoalForm.value.dueDate);
        form.append("visibility",this.createGoalForm.value.goalSubmitView);
        let response = this.apiService.addGoalDetails(form).subscribe(
            response  => {
              if(response.insertedGoalId !== 0){
                  this.goalId = response.insertedGoalId;
                  if(this.userData.roleId == 4 || this.createGoalForm.value.goalSubmitView == 0){
                      this.selectedEmployees.push(this.userData);
                      this.addEmployees_inGoal();
                  }else{
                      this.step = this.step + 1;
                      this.getEmployeeOfCompany();
                  }
              }else{
                // this.step = this.step;
               	return false;
              }
            },
            error => {
                console.log(error);
                return false;
            }
        );
        
    }
  
  
    getEmployeeOfCompany(){
      	var form = new FormData();
        form.append("companyId", this.userData.companyId);
           form.append("userId", "9");
           
          
        let responseEmployee = this.apiService.getMyTeam(form).subscribe(
            responseEmployee  => {
              if(responseEmployee.userData){
              	this.employeeList = responseEmployee.userData;
              }else{
               	return false;
              }
            },
            error => {
                console.log(error);
                return false;
            }
        );
  }
  
  selectEmployeeData(employee,checked){
  	 var target=this.selectedEmployees.find(temp=>temp.id==employee.id);
	 if(checked == false){
	  	this.selectedEmployees.splice(target,1);
	 }else{
	    this.selectedEmployees.push(employee);
	 }  
  }
  addEmployees_inGoal(){
        if(typeof this.selectedEmployees !== 'undefined' && this.selectedEmployees.length > 0){
          	var form = new FormData();
            form.append("companyId", this.userData.companyId);
            form.append("goalId", this.goalId);
        	var EmployeeIDsForGoal = this.selectedEmployees.map(x => x.id);
            form.append("employeeIds", JSON.stringify(EmployeeIDsForGoal));
            
            let responseEmployee = this.apiService.asignEmployeeGoal(form).subscribe(
                responseEmployee  => {
                  if(responseEmployee.insertedEmployeeGoalId){
                    if(this.userData.roleId == 4 || this.createGoalForm.value.goalSubmitView == 0){
                  	    this.router.navigate(['employee/goals']);
                    }else{
                        this.router.navigate(['employee/my-team-goals']);
                    }
                  }else{
                   	return false;
                  }
                },
                error => {
                    console.log(error);
                    return false;
                }
            );
        }else{
            this.employeeSelectionMessage = 'Please select one employee before proceeding.';
            alert(this.employeeSelectionMessage);
            return;
        }
  }
  
}