  <div class="container">
    <div class="page-heading">
      <h2>Performance Review</h2>
      <ol class="breadcrumb p-0 m-0 bg-white">
        <li class="breadcrumb-item">
          <a class="text-secondary" routerLink="../dashboard">Home</a>
        </li>

        

        <li class="breadcrumb-item active">Performance Reviews</li>
      </ol>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="tab_new_style">
          <!-- Nav tabs -->

            <div class="nav nav-tabs nav-fill tabs_list" id="nav-tab" role="tablist">
                <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#reviewrequseted" role="tab" aria-controls="nav-home" aria-selected="true">Reviews Pending</a>
                <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#reviewcompleted" role="tab" aria-controls="nav-profile" aria-selected="false">Reviews Completed</a>
            </div>
            
            <div class="tab-content ctm-padding" id="nav-tabContent">
                <div class="tab-pane fade active show" id="reviewrequseted" role="tabpanel" aria-labelledby="nav-home-tab">
				    <div class="row">
                        <div class="col-md-12">
                            
                 
          
          
          
                            
                            
                            
    <p-table [value]="PReviewAllData" [filterDelay]="0" [globalFilterFields]="['title','deadlineDate_employee','managerName']" 
    styleClass="p-datatable-responsive-demo" dataKey="id" sortMode="multiple" 
    #dt [rows]="10" [paginator]="true" [alwaysShowPaginator]="false"
         [rowHover]="true">
        <ng-template pTemplate="header">
             <div class="table-header">
       
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                </span>
                </div>
  
    
            <tr>
               
                <th pSortableColumn="title">Title <p-sortIcon field="title"></p-sortIcon></th>
                <th pSortableColumn="deadlineDate_employee">Due On <p-sortIcon field="deadlineDate_employee"></p-sortIcon></th>
                <th pSortableColumn="managerName">Manager <p-sortIcon field="managerName"></p-sortIcon></th>
                <th>Action</th>
              
            </tr>
             <tr>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'title','contains')" placeholder="Search by Title" class="p-column-filter form-control">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'deadlineDate_employee','contains')" placeholder="Search by Due On" class="p-column-filter form-control">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'managerName','contains')" placeholder="Search by Manager" class="p-column-filter form-control">
                </th>
                <th>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-review>
            <tr *ngIf="review.status != 1 && PReviewAllData.length != 0">
             
                <td>{{review.title}}</td>
                <td>{{review.deadlineDate_employee| date : 'mediumDate'}}</td>
                <td>{{review.managerName}}</td>
         
                <td>
                    <button pButton pRipple class="btn btn_custom_blue p-mr-2" (click)="provideReview(review.reviewId,review.pruID)">Provide Review</button>
                
                </td>
            </tr>
        </ng-template>
           <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="4" class="text-center">No records found.</td>
                                    </tr>
                                </ng-template>
      
    </p-table>
        
                   
        
                        </div>
                    </div>	
				</div>
				<div class="tab-pane fade" id="reviewcompleted" role="tabpanel" aria-labelledby="nav-profile-tab">
					<div class="row">
                        <div class="col-md-12">
    <p-table #dt1 [value]="PReviewAllData" styleClass="p-datatable-responsive-demo"  [rows]="10" [paginator]="true" [alwaysShowPaginator]="false" [globalFilterFields]="['title','submittedDate','managerName','status']"
         [rowHover]="true" dataKey="id1"
       [showCurrentPageReport]="true">
        <ng-template pTemplate="header">
   <div class="table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                </span>
</div>
       
            <tr>
               
                <th pSortableColumn="title">Title <p-sortIcon field="title"></p-sortIcon></th>
                <th pSortableColumn="submittedDate">Completed On <p-sortIcon field="submittedDate"></p-sortIcon></th>
                <th pSortableColumn="managerName">Manager <p-sortIcon field="managerName"></p-sortIcon></th>
                <th>Action</th>
               
            </tr>
            <tr>
               <th>
                    <input pInputText type="text" (input)="dt1.filter($event.target.value, 'title','contains')" placeholder="Search by Title" class="p-column-filter form-control">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt1.filter($event.target.value,  'submittedDate','contains')" placeholder="Search by Submitted On" class="p-column-filter form-control">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt1.filter($event.target.value,  'managerName','contains')" placeholder="Search by Manager" class="p-column-filter form-control">
                </th>
                <th>
                </th>
                </tr>
        </ng-template>
        <ng-template pTemplate="body" let-review>
            <tr *ngIf="review.status == 1 && PReviewAllData.length != 0">
             
                <td>{{review.title}}</td>
                <td>{{review.submittedDate| date : 'mediumDate'}}</td>
                <td>{{review.managerName}}</td>
         
                <td>
                    <button pButton pRipple class="btn btn_custom_blue p-mr-2" (click)="viewReview(review.reviewId,review.pruID)">View Review</button>
                
                </td>
            </tr>
        </ng-template>
           <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="4" class="text-center">No records found.</td>
                                    </tr>
                                </ng-template>
  
    </p-table>
        
   
        
                        </div>
                    </div>
				</div>
            </div>

          
        </div>

      </div>
    </div>
  </div>