<div class="container">
   <div class="page-heading">
      <a routerLink="../assign-goal" class="btn btn_custom_blue float-right">Assign Goal To Team</a>
      <h2>My Goals</h2>
      <ol class="breadcrumb p-0 m-0 bg-white">
         <li class="breadcrumb-item">
            <a class="text-secondary" routerLink="../dashbard">Home</a>
         </li>
         <li class="breadcrumb-item active">My Goals</li>
      </ol>
   </div>
   <div class="individual-goal-page">
      <div class="golas-flex-sec">
         <div class="table_new_style table-responsive">
            <p-table [value]="MyGoalsData" [filterDelay]="0" [globalFilterFields]="['title','priority','dueDate','percCompleted']" styleClass="p-datatable-responsive-demo" dataKey="id" sortMode="multiple"  #dt [rows]="10" [paginator]="true" [alwaysShowPaginator]="false" 
            [rowHover]="true">
            <ng-template pTemplate="header">
               <div class="table-header">
                  <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                  </span>
               </div>
               <tr>
                  <th pSortableColumn="title">
                     Goal Title 
                     <p-sortIcon field="title"></p-sortIcon>
                  </th>
                  <th pSortableColumn="priority">
                     Priority 
                     <p-sortIcon field="priority"></p-sortIcon>
                  </th>
                  <th pSortableColumn="dueDate">
                     Due On 
                     <p-sortIcon field="dueDate"></p-sortIcon>
                  </th>
                  <th pSortableColumn="percCompleted">
                     % Completed 
                     <p-sortIcon field="percCompleted"></p-sortIcon>
                  </th>
               </tr>
               <tr>
                  <th>
                     <input pInputText type="text" (input)="dt.filter($event.target.value, 'title','contains')" placeholder="Search by Title" class="p-column-filter form-control">
                  </th>
                  <th>
                     <input pInputText type="text" (input)="dt.filter($event.target.value,  'priorityVal','contains')" placeholder="Search by Priority" class="p-column-filter form-control">
                  </th>
                  <th>
                     <input pInputText type="text" (input)="dt.filter($event.target.value,  'dueDate','contains')" placeholder="Search by Due Date" class="p-column-filter form-control">
                  </th>
                  <th>
                     <input pInputText type="text" (input)="dt.filter($event.target.value,  'percCompleted','contains')" placeholder="Search by % Completed" class="p-column-filter form-control">
                  </th>
               </tr>
            </ng-template>
            <ng-template pTemplate="body" let-goal let-i="rowIndex" >
               <tr>
                  <td>{{goal.title}}</td>
                  <td>
                     <span *ngIf="goal.priority == '1'">Low</span>
                     <span *ngIf="goal.priority == '2'">Medium</span>
                     <span *ngIf="goal.priority == '3'">High</span>
                  </td>
                  <td>{{goal.dueDate| date : 'mediumDate'}}</td>
                 
                  <td data-label="% Age Completed">
                 
                     <div class="ctm-rangeslider">
                        <input type="range" [disabled]="goal.dueDate >= currentDate" class="form-control-range-{{goal.goalId}}" min="0" max="100" [value]="goal.percCompleted" id="formControlRange-{{goal.goalId}}" (input)="valueChanged($event.target.value,i)"> 
                        <span>{{goal.percCompleted}}</span>
                     </div>
                  </td>
               </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
               <tr>
                  <td colspan="4" class="text-center">No records found.</td>
               </tr>
            </ng-template>
            </p-table>
         </div>
      </div>
   </div>
</div>