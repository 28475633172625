import { Component } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    
     constructor(private router: Router) {
    }
    public isRouteActive(route) {
        // return this.router.isRouteActive(this.router.generate(route))
    }

  title = 'aestimo';
  
   ngOnInit() {
  (function ($) {
  
    
	$('input[type="number"]').keyup(
	function () {
		var max = parseInt($(this).attr('max'));
		var min = parseInt($(this).attr('min'));
		if ($(this).val() > max) {
			$(this).val(max);
		}
		else if ($(this).val() < min) {
			$(this).val(min);
		}
	}


)



	
});



	
  
  }
}
