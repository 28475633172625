<div class="SuperAdmin">
   <div class="container-fluid  bg-white">
      <div class="row">
         <div class="col-lg-12 bg-light">
            <div class="row mb-4">
               <div class="col-lg-3 col-md-3 mt-4">
                  <div class="card bg-white">
                     <div class="card-header bg-transparent border-0">
                        <div class="text-center py-2 pe-1">
                           <img src="./assets/images/emp2.png" class="img-fluid">
                        </div>
                     </div>
                     <div class="card-body pb-4 text-center" *ngIf="Profile[0]">
                        <h6 class="mb-0 ">{{Profile[0]?.firstName}} {{Profile[0]?.lastName}}</h6>
                        <small><i class="fa fa-envelope text-warning pr-1"></i> {{Profile[0]?.email}}</small>
                        <p class="text-sm my-1"><i class="fa fa-user text-info pr-1"></i>Manager - <b>{{Profile[0]?.managerName}}</b></p>
                        <div>
                           <h6 class="float-left text-success">{{Profile[0]?.salary}}</h6>
                           <div class="bg-dark d-inline  px-2 py-1 shadow text-center border-radius-xl ms-4"><i class="text-white">Grade {{Profile[0]?.gradeId}}</i>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               
               <div class="col-lg-5 col-md-5  mt-4">
                  <div class="card bg-white">
                     <div class="card-header p-0 position-relative mt-n4 mx-3 border-0 bg-transparent">
                        <div class="border-radius-lg py-3 pe-1">
                      
                                   <p-chart type="bar" [data]="data" (onDataSelect)="selectData($event)"></p-chart>
                        </div>
                     </div>
                   
                  </div>
               </div>
               <div class="col-lg-4 col-md-4 mt-4">
                   <div class="counter_dashboard">
                       <div><p>Reviews Pending</p><h3>{{prsInProgress}}</h3></div>
                       <div><p>Reviews Completed</p><h3>{{prsCompleted}}</h3></div>
                       <div><p>Goals In-Progress</p><h3>{{goalsInProgress}}</h3></div>
                       <div><p>Goal Completed</p><h3>{{goalsCompleted}}</h3></div>
                    </div>
                   
                   
               </div>
            </div>
            <div class="card mb-5 shadow bg-white" *ngIf="roleId == '3' ">
               <div class="d-flex">
                  <div class="icon icon-shape icon-lg bg-gradient-primary shadow text-center border-radius-xl mt-n3 ms-4">
                     <i class="fa fa-users opacity-10" aria-hidden="true"></i>
                  </div>
                  <h6 class="mt-3 mb-2 ms-3 ">My Team</h6>
               </div>
               <div class="card-body p-3" >
                  <div class="row">
                     <div class="col-lg-12 col-md-12">
                         
                        <!--   <p-table [value]="MyTeamAllReviews" -->
                        <!--[filterDelay]="0" [globalFilterFields]="['title','submittedDate','employeeName']"-->
                        <!--styleClass="p-datatable-responsive-demo" dataKey="id" -->
                     
                        <!--[rows]="10"-->
                       
                        <!--[paginator]="true" [alwaysShowPaginator]="false"-->
                        <!--[rowHover]="true"-->
                        <!--#dt1>-->
                        <p-table  [alwaysShowPaginator]="false" [filterDelay]="0" [globalFilterFields]="['title','submittedDate','employeeName']" 
                        styleClass="p-datatable-responsive-demo" [value]="MyTeamAllReviews" dataKey="id"  [rows]="10" [paginator]="true"  sortMode="multiple" #dt1>
                        <ng-template pTemplate="header">
                           <div class="table-header">
                              <span class="p-input-icon-left">
                              <i class="pi pi-search"></i>
                              <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                              </span>
                           </div>
                           <tr>
                              <th pSortableColumn="title">
                                 Title 
                                 <p-sortIcon field="title"></p-sortIcon>
                              </th>
                              <th pSortableColumn="submittedDate">
                                 Submitted On 
                                 <p-sortIcon field="submittedDate"></p-sortIcon>
                              </th>
                              <th pSortableColumn="employeeName">
                                 Employee Name 
                                 <p-sortIcon field="employeeName"></p-sortIcon>
                              </th>
                              <th >
                                 Action 
                                 
                              </th>
                           </tr>
                            <tr>
                              <th>
                              <input pInputText class="form-control" type="text" placeholder="Search by Title" (input)="dt1.filter($event.target.value, 'title', 'contains')">
                              </th>
                               <th>
                              <input pInputText class="form-control" type="text" placeholder="Search by Submitted On" (input)="dt1.filter($event.target.value, 'submittedDate', 'contains')">
                              </th>
                               <th>
                              <input pInputText class="form-control" type="text" placeholder="Search by Employee Name" (input)="dt1.filter($event.target.value, 'employeeName', 'contains')">
                              </th>
                              <th></th>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-review>
                           
                       
                           
								<tr>
								   
                                 <td>{{review.title}}</td>
                                 <td>{{review.submittedDate | date :'mediumDate'}}</td>
                                 <td>{{review.employeeName}}</td>
                                 <td>
                                    <button pButton pRipple class="btn btn_custom_blue p-mr-2" (click)="viewReviewEmployee(review.reviewId,review.employeeId,review.pruID)">View Review</button>
                                 </td> 
                                
								</tr>
							
                          
						
                         </ng-template>
                         
                          
                        </p-table>
                 
                     </div>
                  </div>
               </div>
            </div>
            <div class="card mb-4 shadow bg-white">
               <div class="d-flex">
                  <div class="icon icon-shape icon-lg bg-gradient-success shadow text-center border-radius-xl mt-n3 ms-4">
                     <i class="fa fa-star opacity-10" aria-hidden="true"></i>
                  </div>
                  <h6 class="mt-3 mb-2 ms-3 ">My Goals</h6>
               </div>
               <div class="card-body p-3">
                  <div class="row">
                     <div class="col-lg-12 col-md-12">
                        <p-table [columns]="cols" [alwaysShowPaginator]="false" [filterDelay]="0" [globalFilterFields]="['title','priority','percCompleted']" styleClass="p-datatable-responsive-demo" [value]="MyGoalsData" dataKey="id"  [rows]="10" [paginator]="true"  sortMode="multiple" #dt>
                        <ng-template pTemplate="header" let-columns>
                           <div class="table-header">
                              <span class="p-input-icon-left">
                              <i class="pi pi-search"></i>
                              <input pInputText type="text" class="form-control" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search" />
                              </span>
                           </div>
                           <tr>
                              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                              {{col.header}}
                              <p-sortIcon [field]="col.field"></p-sortIcon>
                              </th>
                           </tr>
                           <tr>
                              <th *ngFor="let col of columns" [ngSwitch]="col.field">
                              <input pInputText class="form-control" type="text" placeholder="Search by {{col.header}}" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)">
                              </th>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                           <tr>
                              <td *ngFor="let col of columns">
                                 <span class="p-column-title">{{col.header}}</span>
                                 {{rowData[col.field]}}
                              </td>
                           </tr>
                        </ng-template>
                           <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="3" class="text-center">No records found.</td>
                                    </tr>
                                </ng-template>
                        </p-table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#termsconditions">Open Modal</button>
         <div class="modal" id="termsconditions">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <!-- Modal Header -->
              <div class="modal-header border-0">
                <h4 class="modal-title">Terms & Conditions</h4>
                <button type="button" class="close" data-dismiss="modal">
                  ×
                </button>
              </div>
              <!-- Modal body -->
              <div class="modal-body border-0">
             
                
                    <pre>{{termsAndConditions}}</pre>
                 <!--Terms and conditions are aimed at protecting the business (you). They give business owners the opportunity to set their rules (within applicable law) of how their service or product may be used including, but not limited to, things like copyright conditions, age limits, and the governing law of the contract.-->
              
              </div>
              <!-- Modal footer -->
              <div class="modal-footer border-0">
              <button type="button" class="btn btn-default border" (click)="acceptTerms()">
                  Accept
                </button>
                <button type="button" #closeterms class="btn btn-default border" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
   </div>
</div>