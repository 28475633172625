import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { faUserCircle, faBell } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  collapsed = true;
  icons = {
    user: faUserCircle,
    notification: faBell
  }

  isLoggedIn$: Observable<boolean>;

  constructor() { }

  ngOnInit(): void {
    
  }

  onLogout(){
    //this.authService.logout();
  }

}
