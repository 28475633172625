import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GoalsComponent } from './goals/goals.component';
import { PerformanceReviewComponent } from './performance-review/performance-review.component';
import { EmployeemanagerLayoutComponent } from '../../core-components/employeemanager/employeemanager-layout/employeemanager-layout.component';
import { AssignGoalComponent } from './goals/assigngoal.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './changepassword/changepassword.component';
import { MyTeamPerformanceReviewComponent } from './performance-review/my-team-performance-review.component';
import { MyTeamGoalsComponent } from './goals/my-team-goals.component';
import { ProvidePerformanceReviewComponent } from './performance-review/provide-performance-review.component';
import { PerformanceReviewResponseComponent } from './performance-review/performance-review-response.component';
import { PerformanceReviewResponseEmployeeeComponent  } from './performance-review/performance-review-response-employee.component';
import { ProvidePerformanceReviewSubmissionComponent  } from './performance-review/provide-review-submission.component';

const routes: Routes = [
	{
	   path: 'employee',
	   component: EmployeemanagerLayoutComponent,
	   children: [
	    {path : 'dashboard', component : DashboardComponent},
		{path : 'goals', component : GoalsComponent},
		{path : 'performance-review', component : PerformanceReviewComponent},
		{path : 'assign-goal', component : AssignGoalComponent},
		{path : 'profile', component : ProfileComponent},
		{path : 'change-password', component : ChangePasswordComponent},
		{path : 'my-team-performance-review', component : MyTeamPerformanceReviewComponent},
		{path : 'my-team-goals', component : MyTeamGoalsComponent},
		
		{path : 'provide-performance-review', component : ProvidePerformanceReviewComponent},
 		{path : 'performance-review-response', component : PerformanceReviewResponseComponent},
 		{path : 'performance-review-response-employee', component : PerformanceReviewResponseEmployeeeComponent}

		
	   ]
	},
	{ 
	   path: 'employee',
	   children: [
	       {path: 'provide-review-submission', component : ProvidePerformanceReviewSubmissionComponent}
	    ]
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeemanagerRoutingModule { }
