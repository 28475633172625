 <div class="container">
      <div class="page-heading">
          <a routerLink="../assign-goal" class="btn btn_custom_blue float-right">Assign Goal To Team</a>
        <h2>Individual Goals</h2>
        <ol class="breadcrumb p-0 m-0 bg-white">
          <li class="breadcrumb-item">
            <a class="text-secondary" routerLink="../dashbard">Home</a>
          </li>

     
          <li class="breadcrumb-item active">Individual Goals</li>
        </ol>
      </div>

      <div class="individual-goal-page">
        <h5 style="display: inline-block;">My Team - Individual Goals</h5>
        <!--<div id="reportrange"  class="pull-right daterangepick">
              <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>&nbsp;
              <span></span> <b class="caret"></b>
        </div>-->

        <div class="individual-top-search d-flex align-items-center justify-content-between">
      
        </div>

        <div class="golas-flex-sec">
          <div class="progress-sec-outer">
            <div class="progress manager-progress">
              

                <div class="progress-bar progress-bar-warning p-inprogress" role="progressbar" style="width:{{pendingPerc}}%"> <span>{{blocked}} Goals Blocked.</span>
                  {{pendingPerc}}%
                </div>
                <div class="progress-bar progress-bar-danger p-blocked" role="progressbar" style="width:{{blockedPerc}}%"><span>{{pending}} Goals Inprogress.</span>
                  {{blockedPerc}}%
                </div>
                <div class="progress-bar progress-bar-success p-accomplished" role="progressbar" style="width:{{completedPerc}}%"><span>{{completedPerc}} Goals Accomplished</span>
                  {{completedPerc}}%
                </div>
            </div>
      
          </div>

    
          <p-table [alwaysShowPaginator]="false" [value]="MyTeamGoalsData" [filterDelay]="0" [globalFilterFields]="['title','priority','dueDate','percCompleted','employeeName']" styleClass="p-datatable-responsive-demo" dataKey="id" sortMode="multiple"  #dt [rows]="10" [paginator]="true" 
         [rowHover]="true">
        <ng-template pTemplate="header">
             <div class="table-header">
       
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                </span>
                </div>
  
    
            <tr>
                
                 <th pSortableColumn="employeeName">Employee <p-sortIcon field="employeeName"></p-sortIcon></th>
               
                <th pSortableColumn="title">Goal Title <p-sortIcon field="title"></p-sortIcon></th>
                <th pSortableColumn="priority">Priority <p-sortIcon field="priority"></p-sortIcon></th>
                <th pSortableColumn="dueDate">Due On <p-sortIcon field="dueDate"></p-sortIcon></th>
                <th pSortableColumn="percCompleted">% Completed <p-sortIcon field="percCompleted"></p-sortIcon></th>
              
            </tr>
            <tr>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'employeeName','contains')" placeholder="Search by Employee" class="p-column-filter">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,  'title','contains')" placeholder="Search by Title" class="p-column-filter">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,  'priorityVal','contains')" placeholder="Search by Priority" class="p-column-filter">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,  'dueDate','contains')" placeholder="Search by Due On" class="p-column-filter">
                </th>
                <th>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,  'percCompleted','contains')" placeholder="Search by % Completed" class="p-column-filter">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-goal>
            <tr>
             <td>{{goal.employeeName}}</td>
                <td>{{goal.title}}</td>
                <td><span *ngIf="goal.priority == 1">Low</span><span *ngIf="goal.priority == 2">Medium</span><span *ngIf="goal.priority == 3">High</span></td>
                <td>{{goal.dueDate| date : 'mediumDate'}}</td>
                <td>
                  <div class="progress">
                    <div class="progress-bar" style="width:{{goal.percCompleted}}%">{{goal.percCompleted}}%</div>
                  </div>
                </td>
            </tr>
        </ng-template>
              <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="5" class="text-center">No records found.</td>
                                    </tr>
                                </ng-template>
      
    </p-table>
        
        </div>
        
      </div>
    </div>