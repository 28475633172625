<div class="header-wrapper bg-dark " *ngIf="isLoggedIn$ | async">
    <div class="container-fluid">
        <div class="">
            <nav class="navbar navbar-expand-md navbar-dark bg-daerk fixed-top">
            <!-- <span class="navbar-brand"><app-logo></app-logo></span> -->
            <button class="navbar-toggler" type="button" aria-controls="navbarContent" [attr.aria-expanded]="!collapsed" aria-label="Toggle navigation" (click)="collapsed = !collapsed">
              <span class="navbar-toggler-icon"></span>
            </button>
          
            <div class="navbar-collapse" [class.collapse]="collapsed" id="navbarContent">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link" tabindex="0" role="button" href="#" (click)="$event.preventDefault()">Dashboard</a>
                </li>
                <li class="nav-item" ngbDropdown>
                  <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown1" role="button">
                    Performance
                  </a>
                  <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Create</a>
                    <a ngbDropdownItem routerLink="/performance-review" routerLinkActive="active">Landing Page</a>
                    <!-- <a ngbDropdownItem routerLink="/performance-review" routerLinkActive="active">- All Reviews</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">- My Reviews</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">- Team Feedback</a> -->
                  </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" tabindex="0" role="button" href="#" (click)="$event.preventDefault()">Goals</a>
                </li>
          
                <li class="nav-item" ngbDropdown>
                  <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown2" role="button">
                    Reports
                  </a>
                  <div ngbDropdownMenu aria-labelledby="navbarDropdown2" class="dropdown-menu dropdown-menu-right">
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Appraisal Calculation</a>
                  </div>
                </li>
          
                <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
                  <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown3" role="button">
                    Admin
                  </a>
                  <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Subscription</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">People</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Emails</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Import</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Question Bank</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Pay-for-Performance Matrix</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Company (logo)</a>
                  </div>
                </li>
                <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
                  <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown4" role="button">
                    <!-- <fa-icon [icon]="icons.user"></fa-icon> -->
                  </a>
                  <div ngbDropdownMenu aria-labelledby="navbarDropdown4" class="dropdown-menu">
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Subscription</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">People</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Emails</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Import</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Question Bank</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Pay-for-Performance Matrix</a>
                    <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Company (logo)</a>
                  </div>
                </li>
                <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
                  <a class="nav-link" tabindex="0" role="button">
                    <!-- <fa-icon [icon]="icons.notification"></fa-icon> -->
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
    </div>
</div>
