<div id="__next">
        <div class="container-fluid">
            <h1>{{prData.title}}</h1>
        </div>
</div>

<div>
    
<div class="intro py-3 bg-white text-center" >
    <div class="container">
        <h2 class="my-4">{{prData.title}}</h2>
    </div>
</div>
<div class="result py-3 d-none bg-light text-center" >
    <div class="container lead">
        <p>Question 1/10 </p>
    </div>
</div>


<div class="quiz py-4">
    <h1>{{prData.title}}</h1>
    <div class="container perf_review_fix--container">
        <!-- <h2 class="my-5 text-white">Category</h2> -->

        <div class="question_list perf_review_fix--questionlist">
        <div  *ngFor="let category of uniqueCategory; let i = index" [attr.data-index]="i">
            <div *ngIf="category != ''">
            <div class="cmu_tags">
              <a href="#" >Question Category</a> <h2>{{category}}</h2>
            </div>
            
             <form class="quiz-form">
            <div class="my-5"  *ngFor="let questions of questionsData; let i = index" [attr.data-index]="i"  >
                    <div  *ngIf="i < maxQuestionsCount && i >= minQuestionsCount && questions.categoryName == category">
                    <p class="lead font-weight-normal">
                        {{i+1}}. {{questions.QuestionText}}
                    </p>
                    <div *ngIf="questions.answerType == 1">
                        <div class="form-check my-2" >
                           <div class="rating-bar">
                              <div class="starsecone  d-flex">
                                <div class="
                                      starrating
                                      risingstar
                                      d-flex
                                      justify-content-end
                                      flex-row-reverse
                                    ">
                                    <ng-container *ngFor="let answerTextData of questions.answers; let j = index">
                                    <input type="radio" disabled="disabled" [checked]="answerTextData.answerText == questions.answerId"  value="{{answerTextData.answerText}}" id="{{answerTextData.questionId}}star{{j}}"/>
                                    <label for="{{answerTextData.questionId}}star{{j}}"
                                     ></label>
                                     </ng-container>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="questions.answerType == 2">
                        <div class="form-check my-2" *ngFor="let answerTextData of questions.answers; let j = index" [attr.data-index]="j">
                            <input disabled="disabled" class="mr-3" value="{{answerTextData.answerId}}" type="radio" [checked]="answerTextData.answerId == questions.answerId"  >
                            <label for="A">{{answerTextData.answerText}}</label>
                        </div>
                    </div>
                    <div *ngIf="questions.answerType == 3">
                        <div class="form-check my-2" >
                           <textarea disabled="disabled" name="" id="" cols="30" rows="5" class="form-control" value="{{questions.answerId}}"  placeholder="Enter cmment here" ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            
        </form>
        </div>
        </div>
        <div class="content-section implementation ">
                   <p-paginator [rows]="10" [totalRecords]="totalQuestionCount" [first]="pageNumber"  (onPageChange)="paginate($event)" ></p-paginator>
            </div>
        <div class="clearfix"></div>

       
       

      </div>
    </div>
    </div>
</div>