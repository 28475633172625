<!DOCTYPE html>
<html>
<head>
  <title>Aestimo Login</title>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css?family=Poppins:600&display=swap" rel="stylesheet">
  <script src="https://kit.fontawesome.com/a81368914c.js"></script>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <!--<link rel="stylesheet" href="./assets/css/bootstrap.min.css">-->
  <!--  <link rel="stylesheet" href="./assets/css/font-awesome.min.css">-->
  <!--<link rel="stylesheet" type="text/css" href="./assets/css/login.css">-->
  <script src="./assets/js/jquery.min.js"></script>
  <script src="./assets/js/popper.min.js"></script>
  <script src="./assets/js/bootstrap.min.js"></script>
</head>
<body>
  <p-toast position="bottom-center"  styleClass="custom-toast" ></p-toast>
  <img class="wave" src="./assets/images/wave-1.png">
  <div class="container-login">
    <div class="img">
      <img src="./assets/images/avtar-5.png">
    </div>
    <div class="login-top-cnt">
      <div class="btns">
        <button type="button" class="btn btn-primary" (click)="loginOpen();" *ngIf="!isloginForm" >Sign In</button>
        <button type="button" class="btn btn-primary" (click)="signupOpen();" *ngIf="isloginForm" >Sign Up</button>
      </div>
    </div>
    <div class="login-content">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="isloginForm"  id="loginForm">
          <img src="./assets/images/Aestimo_logo.png" class="login_logo" height="60px;">     
              <div class="input-div one" [ngClass]="{ 'focus' : isFocussed(1) || form.controls.email.dirty }">
                 <div class="i">
                    <i class="fas fa-user"></i>
                 </div>
                 <div class="div">
                    <h5>Username</h5>
                    <input type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && form.controls.email.errors }" class="input" (focus)="addBorder(1)" (blur)="removeBorder()" >
                    <div *ngIf="submitted && form.controls.email.errors" class="invalid-feedback">
                        <div *ngIf="form.controls.email.errors.required">Username is required</div>
                    </div>
                 </div>
              </div>
              <div class="input-div pass" [ngClass]="{ 'focus' : isFocussed(2) || form.controls.password.dirty}">
                 <div class="i"> 
                    <i class="fas fa-lock"></i>
                 </div>
                 <div class="div">
                    <h5>Password</h5>
                    <input type="password"  formControlName="password" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && form.controls.password.errors }" class="input"  (focus)="addBorder(2)" (blur)="removeBorder()">
                    <div *ngIf="submitted && form.controls.password.errors" class="invalid-feedback">
                        <div *ngIf="form.controls.password.errors.required">Password is required</div>
                    </div>
                 </div>
              </div>
              <a  id="forgotOpen" (click)="forgotOpen();">Forgot Password?</a>
              <button  type="submit" class="login-btn">Login</button>
        </form>
        <div id="clientsignupForm"  *ngIf="isclientsignupForm">
        <img src="./assets/images/Aestimo_logo.png" class="login_logo" height="60px;">
        <!-- MultiStep Form -->
        <div class="container-fluid text-left" id="grad12">
          <div class="row justify-content-center mt-0">
            <div class="col-lg-12 ">
              <div class="card px-0">
                <div class="row">
                  <div class="col-md-12 mx-0">
                    <div class="msform">
                      <!-- progressbar -->
                      <ul class="progressbar_tab d-flex justify-content-center">
                        <li class="active" id="start" [ngClass]="{ 'active' : step > 1 }">
                          <strong>Let's Get<br>Started</strong>
                        </li>
                        <li id="aboutself" [ngClass]="{ 'active' : step > 2 }">
                          <strong>About<br>Yourself</strong>
                        </li>
                        <li id="teams" [ngClass]="{ 'active' : step > 3 }">
                          <strong>Customize<br>Your Team</strong>
                        </li>
                        <li id="journey" [ngClass]="{ 'active' : step > 4 }">
                          <strong>Journey With<br> Aestimo</strong>
                        </li>
                      </ul>
                      <!-- fieldsets -->
                      <fieldset *ngIf="step == 1">
                      <form [formGroup]="letsStartForm" (ngSubmit)="onSubmitLetsStart()">
                        <div class="form">
                          <h4>Step 1 - Let's get started</h4>
                          <!---->
                          <div *ngIf="invalidError" style="color:red">
                              {{ invalidError.message }}
                            </div>
                            
                          <div class="input-div one" [ngClass]="{ 'focus' : isFocussed(3) || letsStartForm.controls.companyName.dirty}">
                            <div class="i">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-user ">
                                <g>
                                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                  <circle cx="12" cy="7" r="4"></circle>
                                </g>
                              </svg>
                            </div>
                            <div class="div">
                              <h5>Company-Name</h5>
                              <input type="text" class="input" formControlName="companyName" (focus)="addBorder(3)" [ngClass]="{ 'is-invalid': signupLetstartSubmitted && fls.companyName.errors }">
                              <div *ngIf="signupLetstartSubmitted && fls.companyName.errors" class="invalid-feedback">
                                    <div *ngIf="fls.companyName.errors.required">Company Name is required</div>
                                </div>
                            </div>
                          </div>
                          <div class="input-div one" [ngClass]="{ 'focus' : isFocussed(4) || letsStartForm.controls.businessEmailId.dirty}">
                            <div class="i">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-mail ">
                                <g>
                                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                  </path>
                                  <polyline points="22,6 12,13 2,6"></polyline>
                                </g>
                              </svg>
                            </div>
                            <div class="div">
                              <h5>Business Email-ID</h5>
                              <input type="email" class="input" formControlName="businessEmailId" (focus)="addBorder(4)" [ngClass]="{ 'is-invalid': signupLetstartSubmitted && fls.businessEmailId.errors }"
                                pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$">
                                <div *ngIf="signupLetstartSubmitted && fls.businessEmailId.errors" class="invalid-feedback">
                                    <div *ngIf="fls.businessEmailId.errors.required">Business Email Id is required</div>
                                     <div *ngIf="fls.businessEmailId.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                          </div>
                          <div class="input-div one" [ngClass]="{ 'focus' : isFocussed(5) || letsStartForm.controls.signupPassword.dirty }">
                            <div class="i">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-lock ">
                                <g>
                                  <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </g>
                              </svg>
                            </div>
                            <div class="div">
                              <h5>Password</h5>
                              <input type="password" class="input" autocomplete="off" formControlName="signupPassword" (focus)="addBorder(5)" (blur)="removeBorder()" [ngClass]="{ 'is-invalid': signupLetstartSubmitted && fls.signupPassword.errors }">
                              <div *ngIf="signupLetstartSubmitted && fls.signupPassword.errors" class="invalid-feedback">
                                    <!--<div *ngIf="fls.signupPassword.errors.required">Password is required</div>-->
                                    <div *ngIf="fls.signupPassword.errors.required">Password is required</div>
                                    <div *ngIf="fls.signupPassword.errors.minlength">Password must be at least 6 characters</div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group form-check">
                            <label class="form-check-label">
                              <input class="form-check-input" type="checkbox" formControlName="iAccept" [ngClass]="{ 'is-invalid': signupLetstartSubmitted && fls.iAccept.errors }">
                              I agree to <a data-target="#termsconditions" data-toggle="modal">Terms & Conditions</a> and <a data-target="#privacy_policy" data-toggle="modal">Privacy Policy</a>
                              <div *ngIf="signupLetstartSubmitted && fls.iAccept.errors" class="invalid-feedback">
                                <div *ngIf="fls.iAccept.errors.required">Please Agree Terms & Conditions </div>
                            </div>
                            </label>
                          </div>
                        </div>
                        <input type="submit" name="next" class="next action-button" value="Next Step" />
                        </form>
                      </fieldset>
                      <fieldset *ngIf="step == 2">
                      <form [formGroup]="aboutYourSelfForm" (ngSubmit)="onSubmitAboutYourSelf()">
                        <div class="form">
                          <h4>Step 2 - Tell us about yourself</h4>
                           <div class="input-div one" [ngClass]="{ 'focus' : isFocussed(6) || aboutYourSelfForm.controls.firstName.dirty}">
                            <div class="i">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-user ">
                                <g>
                                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                  <circle cx="12" cy="7" r="4"></circle>
                                </g>
                              </svg>
                            </div>
                            <div class="div">
                              <h5>First name</h5>
                              <input type="text" class="input" formControlName="firstName" (focus)="addBorder(6)" (blur)="removeBorder()" [ngClass]="{ 'is-invalid': signupAboutYourSelfSubmitted  && fay.firstName.errors }">
                              <div *ngIf="signupAboutYourSelfSubmitted && fay.firstName.errors" class="invalid-feedback">
                                <div *ngIf="fay.firstName.errors.required">First Name is required.</div>
                            </div>
                            </div>
                          </div>
                          
                          <div class="input-div one" [ngClass]="{ 'focus' : isFocussed(7) || aboutYourSelfForm.controls.lastName.dirty}">
                            <div class="i">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-user ">
                                <g>
                                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                  <circle cx="12" cy="7" r="4"></circle>
                                </g>
                              </svg>
                            </div>
                            <div class="div">
                              <h5>Last name</h5>
                              <input type="text" class="input" formControlName="lastName" (focus)="addBorder(7)" (blur)="removeBorder()" [ngClass]="{ 'is-invalid': signupAboutYourSelfSubmitted  && fay.lastName.errors }">
                              <div *ngIf="signupAboutYourSelfSubmitted && fay.lastName.errors" class="invalid-feedback">
                                <div *ngIf="fay.lastName.errors.required">Last Name is required</div>
                            </div>
                            </div>
                          </div>
                          <div class="input-div one" [ngClass]="{ 'focus' : isFocussed(8) || aboutYourSelfForm.controls.jobTitle.dirty}">
                            <div class="i">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-tag ">
                                <g>
                                  <path
                                    d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z">
                                  </path>
                                  <line x1="7" y1="7" x2="7" y2="7"></line>
                                </g>
                              </svg>
                            </div>
                            <div class="div" >
                              <h5>Job Title</h5>
                              <input type="text" class="input" formControlName="jobTitle" (focus)="addBorder(8)" (blur)="removeBorder()" [ngClass]="{ 'is-invalid': signupAboutYourSelfSubmitted  && fay.jobTitle.errors }">
                              <div *ngIf="signupAboutYourSelfSubmitted && fay.jobTitle.errors" class="invalid-feedback">
                                <div *ngIf="fay.jobTitle.errors.required">Job Title is required</div>
                            </div>
                            </div>
                          </div>
                          <div class="input-div one" [ngClass]="{ 'focus' : isFocussed(9) || aboutYourSelfForm.controls.phone.dirty}">
                            <div class="i">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-phone ">
                                <g>
                                  <path
                                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                  </path>
                                </g>
                              </svg>
                            </div>
                            <div class="div">
                              <h5>Phone</h5>
                              <!--<input type="number" pattern="/^-?\d+\.?\d*$/"-->
                              <!--  onKeyPress="if(this.value.length==10) return false;" minlength="10" class="input" formControlName="phone" -->
                              <!--  [ngClass]="{ 'is-invalid': signupAboutYourSelfSubmitted  && fay.phone.errors }">-->
                              <input type="text"   class="input" (focus)="addBorder(9)" (blur)="removeBorder()" formControlName="phone" 
                                [ngClass]="{ 'is-invalid': signupAboutYourSelfSubmitted  && fay.phone.errors }">    
                            <div *ngIf="signupAboutYourSelfSubmitted && fay.phone.errors" class="invalid-feedback">
                                <div *ngIf="fay.phone.errors.required">Phone Number is required.</div>
                                 <div *ngIf="fay.phone.errors.pattern">Phone Number should be number only.</div>
                            </div>
                            </div>
                          </div>
                        </div>
                        <input type="button" name="previous" class="previous action-button-previous" value="Previous" (click)="previous()" />
                        <input type="submit" name="next" class="next action-button" value="Next Step"  />
                        </form>
                      </fieldset>
                      <fieldset *ngIf="step == 3">
                        <div class="form">
                          <h4>Step 3 - Customize your team</h4>
                          <div class="input-div one focus">
                            <div class="i">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-users ">
                                <g>
                                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                  <circle cx="9" cy="7" r="4"></circle>
                                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                </g>
                              </svg>
                            </div>
                            <div class="div">
                              <h5># Of Employees</h5>
                              <input type="number" min="1" [(ngModel)]="noOfEmployees"  minlength="1" required (ngModelChange)="getAllMasterPlansData()" class="input">
                              <!--<div class="form-control-feedback"-->
                              <!--    *ngIf="noOfEmployees.errors && (noOfEmployees.dirty || noOfEmployees.touched)">-->
                              <!--    <p *ngIf="noOfEmployees.errors.required"># of Employees is required</p>-->
                              <!--</div>-->
                            </div>
                          </div>
                          <!--<div class="input-div one" [ngClass]="{ 'focus' : isFocussed(11) }">-->
                          <!--  <div class="i">-->
                          <!--    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"-->
                          <!--      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"-->
                          <!--      class="feather feather-dollar-sign ">-->
                          <!--      <g>-->
                          <!--        <line x1="12" y1="1" x2="12" y2="23"></line>-->
                          <!--        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>-->
                          <!--      </g>-->
                          <!--    </svg>-->
                          <!--  </div>-->
                          <!--  <div class="div">-->
                          <!--    <h5>Calculate cost for selected # of employees</h5>-->
                          <!--    <input type="number" min="1" class="input" (focus)="addBorder(11)" (blur)="removeBorder()">-->
                          <!--  </div>-->
                          <!--</div>-->
                          <div class="input-div one focus">
                            <div class="i">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-dollar-sign ">
                                <g>
                                  <line x1="12" y1="1" x2="12" y2="23"></line>
                                  <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </g>
                              </svg>
                            </div>
                            <div class="div">
                              <h5>Monthly cost</h5>
                              <input type="number" min="1" class="input" [readonly]="true" [(ngModel)]="amountBasedOnSelection" (focus)="addBorder(12)">
                            </div>
                          </div>
                          <p>(The contract term is for minimum period of 12 months. Your card will be automatically
                            charged everymonth for the amount due)</p>
                        </div>
                        <input type="button" name="previous" class="previous action-button-previous" value="Previous" (click)="previous()"/>
                        <input type="button" name="make_payment" class="next action-button" value="Next" (click)="submit()" />
                      </fieldset>
                      <fieldset class="serach-em-sec" *ngIf="step == 4">
                      <form [formGroup]="paymentForm" (ngSubmit)="getCardToken()">
                        <div class="form">
                          <h4>Step 4 - You are Just one step away from starting your journey with Aestimo</h4>
                          <!--<stripe-card #stripeCard-->
                          <!--    (catch) = "onStripeError($event)"-->
                          <!--    [(complete)] = "cardDetailsFilledOut"-->
                          <!--    [(invalid)] = "invalidError"-->
                          <!--    (cardMounted) = "cardCaptureReady = 1"-->
                          <!--    (paymentMethodChange) = "setPaymentMethod($event)"-->
                          <!--    (stripe) = "getttt($event)"-->
                          <!--    (tokenChange) = "setStripeToken($event)"-->
                          <!--    (sourceChange) = "setStripeSource($event)"-->
                          <!--  ></stripe-card>-->
                            
                        <!--<button type="button" (click)="stripeCard.createToken(extraData)">Payment</button>-->
                            
                        <div class="input-div one focus">
                            <div class="i">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-dollar-sign ">
                                <g>
                                  <line x1="12" y1="1" x2="12" y2="23"></line>
                                  <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </g>
                              </svg>
                            </div>
                            <div class="div">
                              <h5>Amount</h5>
                              <input type="text" min="1" [readonly]="true" formControlName="amountBasedOnSelectionView"  class="input" (focus)="addBorder(13)">
                            </div>
                          </div>
                          <!--<div class="input-div one" [ngClass]="{ 'focus' : isFocussed(14) }">-->
                          <!--  <div class="i">-->
                          <!--    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"-->
                          <!--      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"-->
                          <!--      class="feather feather-user ">-->
                          <!--      <g>-->
                          <!--        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>-->
                          <!--        <circle cx="12" cy="7" r="4"></circle>-->
                          <!--      </g>-->
                          <!--    </svg>-->
                          <!--  </div>-->
                          <!--  <div class="div">-->
                          <!--    <h5>Name on Card</h5>-->
                          <!--    <input type="text" class="input" (focus)="addBorder(14)" (blur)="removeBorder()">-->
                          <!--  </div>-->
                          <!--</div>-->
                          <div class="row">
                            <div class="col-md-9">
                              <div class="input-div one" [ngClass]="{ 'focus' : isFocussed(15) || paymentForm.controls.cardnumber.dirty }" >
                                <div class="i">
                                  <svg viewBox="64 64 896 896" focusable="false" data-icon="credit-card" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                      d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-792 72h752v120H136V232zm752 560H136V440h752v352zm-237-64h165c4.4 0 8-3.6 8-8v-72c0-4.4-3.6-8-8-8H651c-4.4 0-8 3.6-8 8v72c0 4.4 3.6 8 8 8z">
                                    </path>
                                  </svg>
                                </div>
                                <div class="div">
                                  <h5>Credit-card Number</h5>
                                  <input type="text" pattern="/^-?\d+\.?\d*$/"
                                    onKeyPress="if(this.value.length==16) return false;" maxlength="16" minlength="16"
                                    class="input" (focus)="addBorder(15)"  formControlName="cardnumber" (blur)="removeBorder()"  [ngClass]="{ 'is-invalid': paymentSubmitted &&  fap.cardnumber.errors }">
                                  <div *ngIf="paymentSubmitted && fap.cardnumber.errors" class="invalid-feedback">
                                    <div *ngIf="fap.cardnumber.errors.required">Credit-card Number is required</div>
                                    <div *ngIf="fap.cardnumber.errors.minlength">Credit-card Number must be at least 16 characters</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="input-div one cvv-field" [ngClass]="{ 'focus' : isFocussed(16) || paymentForm.controls.cvv.dirty}">
                                <div class="i">
                                  <svg viewBox="64 64 896 896" focusable="false" data-icon="credit-card" width="1em"
                                    height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                      d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-792 72h752v120H136V232zm752 560H136V440h752v352zm-237-64h165c4.4 0 8-3.6 8-8v-72c0-4.4-3.6-8-8-8H651c-4.4 0-8 3.6-8 8v72c0 4.4 3.6 8 8 8z">
                                    </path>
                                  </svg>
                                </div>
                                <div class="div">
                                  <h5>CVV</h5>
                                  <input type="password" pattern="/^-?\d+\.?\d*$/"
                                    onKeyPress="if(this.value.length==3) return false;" maxlength="3"
                                    class="input" (focus)="addBorder(16)" formControlName="cvv" (blur)="removeBorder()"  [ngClass]="{ 'is-invalid': paymentSubmitted &&  fap.cvv.errors }">
                                  <div *ngIf="paymentSubmitted && fap.cvv.errors" class="invalid-feedback">
                                    <div *ngIf="fap.cvv.errors.required">CVV is required</div>
                                    <div *ngIf="fap.cvv.errors.minlength">CVV must be at least 3 characters</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="sel1">Expiry On:</label>
                                <select class="form-control" formControlName="mm" placeholder='MM' id="sel1"  [ngClass]="{ 'is-invalid': paymentSubmitted &&  fap.mm.errors }">
                                  <option value="01">Jan</option>
                                  <option value="02">Feb</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">Aug</option>
                                  <option value="09">Sep</option>
                                  <option value="10">Oct</option>
                                  <option value="11">Nov</option>
                                  <option value="12">Dec</option>
                                </select>
                                <div *ngIf="paymentSubmitted && fap.mm.errors" class="invalid-feedback">
                                    <div *ngIf="fap.mm.errors.required">Expiry Month is required</div>
                                  </div>
                                <!--<input class='form-control card-expiry-month' formControlName="mm" placeholder='MM' size='2' type='text'>-->
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="selyear">&nbsp;</label>
                                <!--<input class='form-control card-expiry-year' formControlName="yy" placeholder='YYYY' size='4' type='text'>-->
                                <select class="form-control" id="selyear" formControlName="yy" placeholder='YYYY'  [ngClass]="{ 'is-invalid': paymentSubmitted &&  fap.yy.errors }">
                                  <!--<option value="2021">2021</option>-->
                                  <!--<option value="2022">2022</option>-->
                                  <!--<option value="2023">2023</option>-->
                                  <!--<option value="2024">2024</option>-->
                                  <option *ngFor="let year of years">{{year}}</option>
                                </select>
                                <div *ngIf="paymentSubmitted && fap.yy.errors" class="invalid-feedback">
                                    <div *ngIf="fap.yy.errors.required">Expiry Year is required</div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <input type="button" name="previous" class="previous action-button-previous" value="Previous" (click)="previous()"/>
                        <input  type="submit" name="make_payment" [disabled]="paymentSubmitted == true" class="next action-button" value="Payment">
                        </form>
                        <!--<button type="button" name="make_payment"-->
                        <!--  class="next action-button" (click)="stripeCard.createToken(extraData)">Payment</Button>-->
                        <!--<button type="button" class="next action-button" (click)="stripeCard.createToken(extraData)">Payment</button>-->
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        
   
        <!--End Performance steps-->
      </div>
       
        <form id="forgotpassword"  *ngIf="isforgotpassword" >
         <img src="./assets/images/Aestimo_logo.png" class="login_logo" height="60px;">  
          <div class="input-div one" [ngClass]="{ 'focus' : isFocussed(1) }">
            <div class="i">
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail "><g><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></g></svg>
            </div>
            <div class="div">
                    <h5>Email ID</h5>
                    <input type="text" class="input" (focus)="addBorder(1)" (blur)="removeBorder()">
            </div>
          </div>
          <button class="login-btn">Send Mail</button>
        </form>
    </div>
       <div class="modal" id="termsconditions">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header border-0">
            <h4 class="modal-title">Terms & Conditions</h4>
            <button type="button" class="close" data-dismiss="modal">
              ×
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body border-0">
         
            
                <pre>{{termsAndConditions}}</pre>
             <!--Terms and conditions are aimed at protecting the business (you). They give business owners the opportunity to set their rules (within applicable law) of how their service or product may be used including, but not limited to, things like copyright conditions, age limits, and the governing law of the contract.-->
          
          </div>
          <!-- Modal footer -->
          <div class="modal-footer border-0">
            <button type="button" class="btn btn-default border" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    
    
     <div class="modal" id="privacy_policy">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header border-0">
            <h4 class="modal-title">Privacy Policy</h4>
            <button type="button" class="close" data-dismiss="modal">
              ×
            </button>
          </div>
          <!-- Modal body -->
          <div class="modal-body border-0">
    
              <!--Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.-->
           <pre>{{policy}}</pre>     

          
          </div>
          <!-- Modal footer -->
          <div class="modal-footer border-0">
            <button type="button" class="btn btn-default border" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  <script src="./assets/js/main.js"></script>
</body>
</html>