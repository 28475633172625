import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LogoComponent } from './core-components/logo/logo.component';
import { HeaderComponent } from './core-components/header/header.component';
import { LoginComponent } from './pages/login/login.component';
import { SuperadminloginComponent } from './pages/superadminlogin/superadminlogin.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { SuperadminModule } from './pages/superadmin/superadmin.module';
//import { HradminModule } from './pages/hradmin/hradmin.module';
import { EmployeemanagerModule } from './pages/employeemanager/employeemanager.module';
import { CommonModule } from "@angular/common";

import { AppComponent } from './app.component';
import {ToastModule} from 'primeng/toast';
import { StripeModule } from "stripe-angular"

//interceptor service
import { TokenInterceptorService } from "./_services/auth/token-interceptor.service"


@NgModule({
  declarations: [
    AppComponent,
    LogoComponent,
    HeaderComponent,
    LoginComponent,
    SuperadminloginComponent 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    //NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
//    SuperadminModule,
//    HradminModule,
    EmployeemanagerModule,
    CommonModule,
    ToastModule,
    [ StripeModule.forRoot("") ]

  ],
  providers: [
  {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
  }    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
