 <section id="employee-profile">
    <div class="container">

      <div class="page-heading">
        <h2>Profile</h2>
        <ol class="breadcrumb p-0 m-0 bg-white">
          <li class="breadcrumb-item">
            <a class="text-secondary" routerLink="../dashboard">Home</a>
          </li>

          

          <li class="breadcrumb-item active">Profile</li>
        </ol>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-5">
          <div class="short-info-details" *ngIf="Profile[0]">
            <!-- <h5>Profile</h5> -->
            <div class="emp-shortname">
              <h5>SK</h5>
              <h6>{{Profile[0]?.firstName}} {{Profile[0]?.lastName}}</h6>
            </div>
            <div class="emp-contact-details">
              <p>
                <span class="profile-icon">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail ">
                    <g>
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </g>
                  </svg>
                </span>
                <span> {{Profile[0]?.email}}</span>
              </p>

              <p>
                <span class="profile-icon"><svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smartphone ">
                    <g>
                      <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
                      <line x1="12" y1="18" x2="12" y2="18"></line>
                    </g>
                  </svg></span>
                <span> {{Profile[0]?.phone}}</span>
              </p>

              <p>
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin ">
                  <g>
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                    <circle cx="12" cy="10" r="3"></circle>
                  </g>
                </svg>
                <span *ngIf="Profile[0]?.city"> {{Profile[0]?.city}}</span>
                  <span *ngIf="!Profile[0]?.city"> Not Mentioned</span>
              </p>
              
              <p>
                <i class="far fa-address-card"></i>&nbsp;
                <span> EMP{{Profile[0]?.jobProfileId}}</span>
              </p>

              <!--<button type="button" class="btn btn_custom_blue">EMP001</button>-->

            </div>
          </div>
        </div>

        <div class="col-lg-9 col-md-7 ">
          <div class="emp-personal-info">
            <div class="card">
              <div class="card-header">
                Personal Information
              </div>

              <div class="card-body">
                <div class="personal-details">
                  <div class="p-details">
                    <label>First Name</label>
                    <p class="output" *ngIf="Profile[0]?.firstName">{{Profile[0]?.firstName}}</p>
                            <p class="output" *ngIf="!Profile[0]?.firstName">Not Mentioned</p>
                  </div>
                  <div class="p-details">
                    <label>Last Name</label>
                    <p class="output" *ngIf="Profile[0]?.lastName">{{Profile[0]?.lastName}}</p>
                            <p class="output" *ngIf="!Profile[0]?.lastName">Not Mentioned</p>
                  </div>
                  <div class="p-details">
                    <label>Gender</label>
                    <p class="output" *ngIf="Profile[0]?.gender">{{Profile[0]?.gender}}</p>
                    <p class="output" *ngIf="!Profile[0]?.gender">Not Mentioned</p>
                  </div>
                  <div class="p-details">
                    <label>EmailID</label>
                    <p class="output" *ngIf="Profile[0]?.email">{{Profile[0]?.email}}</p>
                            <p class="output" *ngIf="!Profile[0]?.email">Not Mentioned</p>
                  </div>
                  <div class="p-details">
                    <label>Password </label>
                    <p class="output" *ngIf="Profile[0]?.password">{{Profile[0]?.password}}</p>
                            <p class="output" *ngIf="!Profile[0]?.password">Not Mentioned</p>
                  </div>
                  <div class="p-details">
                    <label>EmployeeID</label>
                    <p class="output" *ngIf="Profile[0]?.jobProfileId">EMP{{Profile[0]?.jobProfileId}}</p>
                            <p class="output" *ngIf="!Profile[0]?.jobProfileId">Not Mentiones</p>
                  </div>
                  <div class="p-details">
                    <label>Grade</label>
                    <p class="output" *ngIf="Profile[0]?.gradeId">Grade {{Profile[0]?.gradeId}}</p>
                            <p class="output" *ngIf="!Profile[0]?.gradeId">Not Mentioned</p>
                  </div>
                  <div class="p-details">
                    <label>Job-Profile </label>
                    <p class="output" *ngIf="Profile[0]?.jobName">Account Manager</p>
                              <p class="output" *ngIf="!Profile[0]?.jobName">Not Mentioned</p>
                  </div>
                  <div class="p-details">
                    <label>City</label>
                    <p class="output" *ngIf="Profile[0]?.city">{{Profile[0]?.city}}</p>
                            <p class="output" *ngIf="!Profile[0]?.city">Not Mentioned</p>
                  </div>
                  <div class="p-details">
                    <label>Manager Name</label>
                    <p class="output" *ngIf="Profile[0]?.managerName">CNPL Manager</p>
                           <p class="output" *ngIf="!Profile[0]?.managerName">Not Mentioned</p>
                  </div>
                  <div class="p-details">
                    <label>Manager Email-ID</label>
                    <p class="output" *ngIf="Profile[0]?.managerEmail">cnplmanage@gmail.com</p>
                            <p class="output" *ngIf="!Profile[0]?.managerEmail">Not Mentioned</p>
                  </div>
                  <div class="p-details">
                    <label>Salary</label>
                    <p class="output" *ngIf="Profile[0]?.salary">{{Profile[0]?.salary}}</p>
                    
                     <p class="output" *ngIf="!Profile[0]?.salary">Not Mentioned</p>
                  </div>
                  <div class="p-details">
                    <label >Role</label>
                    <p class="output" *ngIf="Profile[0]?.RoleName">Manager</p>
                       <p class="output" *ngIf="!Profile[0]?.RoleName">Not Mentioned</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>