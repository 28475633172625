  <div class="container">
        <div class="page-heading d-flex align-items-center justify-content-between">
            <div class="page-heading-title">
                <h2 *ngIf="userData.roleId == 3">Assign Goal to Team</h2>
                 <h2 *ngIf="userData.roleId != 3">Assign Goal to Self</h2>
                <ol class="breadcrumb p-0 m-0 bg-white">
                    <li class="breadcrumb-item">
                        <a class="text-secondary" href="../dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" *ngIf="userData.roleId == 3">Assign Goal to Team</li>
                    <li class="breadcrumb-item active" *ngIf="userData.roleId != 3">Assign Goal to Self</li>
                </ol>
            </div>

        </div>
    </div>
    <!--Performance steps-->

    <!-- MultiStep Form -->
    <div class="container-fluid create-goal-emp">
        <div class="row justify-content-center mt-0">
            <div class="col-lg-10">
                <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                    <div class="row">
                        <div class="col-md-12 mx-0">
                            <div class="msform">
                                <!-- progressbar -->
                                <ul class="progressbar_tab" *ngIf="userData.roleId == 3">
                                      <li class="active" id="account">
                                        <!-- <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings "><g><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></g></svg> -->
                                        <strong>Setting</strong>
                                    </li>
                                     <li  id="confirm" [ngClass]="{ 'active' : step > 1 }" >
                                        <!--  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users "><g><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></g></svg> -->
                                        <strong>Employees</strong>
                                    </li>
                                  

                                   

                                </ul>
                                <fieldset *ngIf="step == 1 || userData.roleId != 3">
                                    <form [formGroup]="createGoalForm" (ngSubmit)="onSubmit()">
                                    <div class="form-card first-step">
                                        <center class="mb-5" *ngIf="userData.roleId == 3">
                                            <h2 class="fs-title text-center">Setting</h2>
                                            <small>Select employees to whom goal has to be assigned. </small>
                                        </center>
                                        <div class="namecycle-field form-group">
                                            <label>Goal Title</label>
                                            <input type="text" name="goaltitle" formControlName="title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" class="form-control" />
                                            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                                <div *ngIf="f.title.errors.required">Goal Title is required</div>
                                            </div>
                                        </div>
                                        <div class="namecycle-field form-group">
                                            <label>Description</label>
                                            <textarea name="" class="form-control" id="" cols="30" rows="5" formControlName="descriptionData" [ngClass]="{ 'is-invalid': submitted && f.descriptionData.errors }"></textarea>
                                            <div *ngIf="submitted && f.descriptionData.errors" class="invalid-feedback">
                                                <div *ngIf="f.descriptionData.errors.required">Goal Description is required</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="namecycle-field form-group">
                                                    <label>Due Date</label>
                                                    <input type="date" name="dueon" class="form-control" formControlName="dueDate" [ngClass]="{ 'is-invalid': submitted && f.dueDate.errors }"/>
                                                    <div *ngIf="submitted && f.dueDate.errors" class="invalid-feedback">
                                                        <div *ngIf="f.dueDate.errors.required">Due Date is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="namecycle-field">
                                                    <label>Visibility</label>
                                                    <select name="" class="form-control" id="" formControlName="goalSubmitView" [ngClass]="{ 'is-invalid': submitted && f.goalSubmitView.errors }">
                                                        <!--<option value="2">Admin</option>-->
                                                        <option value="1" *ngIf="userData.roleId == 3">Manager</option>
                                                        <option value="0">Only Me</option>
                                                    </select>
                                                    <div *ngIf="submitted && f.goalSubmitView.errors" class="invalid-feedback">
                                                        <div *ngIf="f.goalSubmitView.errors.required">Visibility is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="namecycle-field form-group">
                                                    <label>Priority</label>
                                                    <select name="" class="form-control" id="" formControlName="priority" [ngClass]="{ 'is-invalid': submitted && f.priority.errors }">
                                                        <option value="1">Low</option>
                                                        <option value="2">Medium</option>
                                                        <option value="3">High</option>
                                                    </select>
                                                    <div *ngIf="submitted && f.priority.errors" class="invalid-feedback">
                                                        <div *ngIf="f.priority.errors.required">Priority is required</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--<input type="button" name="previous" class="previous action-button-previous"-->
                                    <!--    value="Previous" />-->
                                    <button type="submit" class="next1 action-button">Confirm</button>
                                <!--<input type="button" (click)="submit();" name="next" class="next1 action-button" value="Confirm" />-->
                                    </form>
                                </fieldset>
                                
                                 <fieldset class="serach-em-sec" *ngIf="step==2">
                                    <div class="form-card text-center first-step">
                                        <h2 class="fs-title text-center">Empolyees</h2>
                                        <p>Select employee to include in this cycle</p>
                                       

                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend border-right-0">
                                                <span class="input-group-text border-radius-0 border-right-0 bg-white"
                                                    id="basic-addon1"><i class="fa fa-search"></i></span>
                                            </div>
                                            <input type="text" class="form-control border-left-0 m-0"
                                                placeholder="Search" aria-label="Username"
                                                aria-describedby="basic-addon1">
                                        </div>
                                        <div class="text-center mb-5">0 people included in this cycle</div>

                                        <div class="table_new_style table-responsive">
                                                  <p-table [alwaysShowPaginator]="false" styleClass="p-datatable-customers p-datatable-responsive-demo" [columns]="colsForEmployeeList" [value]="employeeList" dataKey="id" [filterDelay]="0" [globalFilterFields]="['gradeName','jobName','managerName']"  [rows]="10" [paginator]="true"  sortMode="multiple" #employees  >
                                                <ng-template pTemplate="header" let-columns>
                                                    <div class="table-header">
                                                      <span class="p-input-icon-left">
                                                      <i class="pi pi-search"></i>
                                                      <input pInputText type="text" class="form-control" (input)="employees.filterGlobal($event.target.value, 'contains')" placeholder="Search" />
                                                      </span>
                                                   </div>
                                                    <tr>
                                                        <th>
                                                         <p-checkbox (click)="selectEmployeeData(emp.checked)" #emp></p-checkbox>
                                                        </th>
                                                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                                            {{col.header}}
                                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                        
                                                        </th>
                                                        <th *ngFor="let col of columns" [ngSwitch]="col.field">
                                                            <input pInputText type="text" placeholder="Search by {{col.header}}" (input)="employees.filter($event.target.value, col.field, col.filterMatchMode)">
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                                    <tr [pSelectableRow]="rowData">
                                                        <td>
                                                            <p-tableCheckbox [value]="rowData" (click)="selectEmployeeData(rowData,empl.checked)" #empl></p-tableCheckbox>
                                                        </td>
                                                        <td *ngFor="let col of columns">
                                                             <span class="p-column-title">{{col.header}}</span>
                                                            {{rowData[col.field]}}
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                  <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="4" class="text-center">No records found.</td>
                                                    </tr>
                                                </ng-template>
                                              </p-table>

                                        </div>
                                    </div>
                                    
                                    <input type="button" name="add_employee" class="next action-button" (click)="addEmployees_inGoal()" value="Add Employees" />

                                     
                                     
                                    
                                </fieldset>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End Performance step-->