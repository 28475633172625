  <div class="container">
    <div class="page-heading">
      <h2>My Team Performance Review</h2>
      <ol class="breadcrumb p-0 m-0 bg-white">
        <li class="breadcrumb-item">
          <a class="text-secondary" routerLink="../dashboard">Home</a>
        </li>
        <li class="breadcrumb-item active">My Team Performance Reviews</li>
      </ol>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="tab_new_style">
          <!-- Nav tabs -->

            <div class="nav nav-tabs nav-fill tabs_list" id="nav-tab" role="tablist">
                <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#reviewrequseted" role="tab" aria-controls="nav-home" aria-selected="true">Reviews Pending</a>
                <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#reviewcompleted" role="tab" aria-controls="nav-profile" aria-selected="false">Reviews Completed</a>
            </div>
            
            <div class="tab-content ctm-padding" id="nav-tabContent">
                <div class="tab-pane fade active show" id="reviewrequseted" role="tabpanel" aria-labelledby="nav-home-tab">
				    <div class="row">
                        <div class="col-md-12">          
                             <p-table [value]="PReviewMyTeamData"  [alwaysShowPaginator]="false" [filterDelay]="0" [globalFilterFields]="['title','deadlineDate_employee','employeeName']" styleClass="p-datatable-responsive-demo" dataKey="id" sortMode="multiple"  #dt [rows]="10" [paginator]="true" 
         [rowHover]="true">
        <ng-template pTemplate="header">
             <div class="table-header">
       
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                </span>
                </div>
  
    
            <tr>
                  <th pSortableColumn="employeeName">Employee <p-sortIcon field="employeeName"></p-sortIcon></th>
                <th pSortableColumn="Title">Title <p-sortIcon field="Title"></p-sortIcon></th>
                <th pSortableColumn="DueOn">Due On <p-sortIcon field="DueOn"></p-sortIcon></th>
             
                <th pSortableColumn="Action">Action <p-sortIcon field="Action"></p-sortIcon></th>
              
            </tr>
               <tr>
                        <th >
                            <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value,'employeeName', 'contains')" placeholder="Search by Employee" >
                        </th>
                        <th >
                            <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value,'title', 'contains')" placeholder="Search by Title" >
                        </th>
                          <th >
                            <input class="form-control" pInputText type="text" (input)="dt.filter($event.target.value,'deadlineDate_employee', 'contains')" placeholder="Search by Due On">
                        </th>
                        
                        
                        <th>
                        </th>
                    </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
           <ng-container *ngFor= "let review of item.userPRData">
            <tr *ngIf="review.managerStatus != 1">
               <td>{{review.employeeName}}</td>
                <td>{{review.title}}</td>
                <td>{{review.deadlineDate_employee| date : 'mediumDate'}}</td>
                <td *ngIf="review.status == 1">
                    <button pButton pRipple class="btn btn_custom_blue p-mr-2" (click)="provideReview(review.reviewId,review.pruID)">Provide Review</button>
                </td>
                <td *ngIf="review.status != 1">
                    Employee has not submitted review yet.
                </td>
            </tr>
            </ng-container>
        </ng-template>
       <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="4" class="text-center">No records found.</td>
                                    </tr>
                                </ng-template>
    </p-table>
                        </div>
                    </div>	
				</div>
				<div class="tab-pane fade" id="reviewcompleted" role="tabpanel" aria-labelledby="nav-profile-tab">
					<div class="row">
                        <div class="col-md-12">
                            <p-table [value]="PReviewMyTeamData" [filterDelay]="0" [globalFilterFields]="['title','submittedDate','employeeName']" styleClass="p-datatable-responsive-demo" dataKey="id" sortMode="multiple"  #dt1 [rows]="10" [paginator]="true" 
             [rowHover]="true">
            <ng-template pTemplate="header">
                 <div class="table-header">
           
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                    </span>
                    </div>
      
        
                <tr>
                      <th pSortableColumn="employeeName">Employee <p-sortIcon field="employeeName"></p-sortIcon></th>
                    <th pSortableColumn="title">Title <p-sortIcon field="title"></p-sortIcon></th>
                    <th pSortableColumn="submittedDate">Due On <p-sortIcon field="submittedDate"></p-sortIcon></th>
                 
                    <th pSortableColumn="Action">Action <p-sortIcon field="Action"></p-sortIcon></th>
                    <th pSortableColumn="Action">Action <p-sortIcon field="Action"></p-sortIcon></th>
                  
                </tr>
                       <tr>
                        <th >
                            <input class="form-control" pInputText type="text" (input)="dt1.filter($event.target.value,'employeeName', 'contains')" placeholder="Search by Employee" >
                        </th>
                        <th >
                            <input class="form-control" pInputText type="text" (input)="dt1.filter($event.target.value,'title', 'contains')" placeholder="Search by Title" >
                        </th>
                          <th >
                            <input class="form-control" pInputText type="text" (input)="dt1.filter($event.target.value,'submittedDate', 'contains')" placeholder="Search by Due On">
                        </th>
                          <th>
                        </th>
                        
                        <th>
                        </th>
                    </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
               <ng-container *ngFor= "let review of item.userPRData">
                <tr *ngIf="review.status ==1">
                   <td>{{review.employeeName}}</td>
                    <td>{{review.title}}</td>
                    <td>{{review.submittedDate| date : 'mediumDate'}}</td>
                    <td *ngIf="review.status == 1">
                        <button pButton pRipple class="btn btn_custom_blue p-mr-2" (click)="viewReviewEmployee(review.reviewId,review.employeeId,review.pruID)">View Employee Review</button>
                    </td>
                    <td *ngIf="review.status != 1">
                        Employee review is pending
                    </td>
                    <td *ngIf="review.managerStatus == 1">
                        <button pButton pRipple class="btn btn_custom_blue p-mr-2" (click)="viewReview(review.reviewId,userId,review.pruID)">View My Review</button>
                    </td>
                    <td *ngIf="review.managerStatus != 1">
                        Your review is Pending
                    </td>
                </tr>
                </ng-container>
            </ng-template>
             <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="5" class="text-center">No records found.</td>
                                        </tr>
                                    </ng-template>
          
        </p-table>
                        </div>
                    </div>
				</div>
            </div>
        </div>
      </div>
    </div>
  </div>