import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../common-modules/services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-performance-review',
  templateUrl: './performance-review.component.html',
  styleUrls: []
})
export class PerformanceReviewComponent implements OnInit {
PReviewAllData = [];
userData:any = [];
constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService) { }

  ngOnInit(): void {
      if (localStorage.getItem("userData") === null) {
      this.router.navigate(['login']);
    }else{
      this.userData = JSON.parse(localStorage.getItem("userData"));
    }
      this.getMyAllReviews();
      
   
  }
  
  
  
  getMyAllReviews(){
      var form = new FormData();
      form.append("userId",this.userData.id);
      form.append("role",'4');
    	form.append("companyId", this.userData.companyId);
  		let response = this.apiService.getMyAllReviews(form).subscribe(
        response  => {
        if(response.userPRData){
        	this.PReviewAllData = response.userPRData;
        // 	console.log('PReviewAllData',this.PReviewAllData);
        }
        //   console.log(response);
        },
          error => (
            console.log(error)
          )
    )
  }
  
provideReview(reviewId: number,pruID: number) {
    // console.log('provideReview',reviewId);
    localStorage.setItem('provideReviewId',JSON.stringify(reviewId));
    localStorage.setItem('pruID',JSON.stringify(pruID));
    localStorage.setItem('selfOrTeam',JSON.stringify(2));
    this.router.navigate(['employee/provide-performance-review']);
}
  
  
  viewReview(reviewId: number,pruID: number) {
    //   console.log('viewReview',reviewId);
      localStorage.setItem('viewReviewId',JSON.stringify(reviewId));
      localStorage.setItem('pruIDView',JSON.stringify(pruID));
    //   localStorage.setItem('viewReviewEmployeeId',JSON.stringify(this.userData.id));
      
      this.router.navigate(['employee/performance-review-response-employee']);
 }
  

}
